import Select from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { usePaymentOptionsQuery } from '@/api/paymentOptions/paymentOptionsApi';
import { Column, Row } from '@/components/Grid';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { CurrencyCode } from '@/types/CurrencyCode';

import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';
import { RedemptionBankAccountNumber } from './RedemptionBankAccountNumber';

interface Props {
  paymentIndex: number;
  paymentCurrency: CurrencyCode;
}

const otherBankAccountOption = 'other';

export const RedemptionPaymentOptions: React.FC<Props> = ({
  paymentIndex,
  paymentCurrency,
}) => {
  const { data } = usePaymentOptionsQuery();
  const texts = useTexts();

  const { setFieldValue } = useFormikContext<RedemptionOrderFormValues>();

  const [field, meta, helper] = useField<string>(
    `payments[${paymentIndex}].bankAccountNumber`,
  );

  const [paymentPlacementField] = useField<string>(
    `payments[${paymentIndex}].paymentPlacement`,
  );

  const currencyFilteredPaymentOptions = data!.paymentOptions.filter(
    (paymentOption) => paymentOption.denominationCurrency === paymentCurrency,
  );

  const options = [
    ...currencyFilteredPaymentOptions.map((paymentOption) => ({
      value: paymentOption.accountNumber,
      label: paymentOption.accountNumber,
    })),
    {
      value: otherBankAccountOption,
      label: texts.orders.form.payment.manualBankAccount,
    },
  ];

  const getInitialPaymentOption = (): string => {
    const isExistingBankAccount =
      field.value &&
      data!.paymentOptions.some(
        (paymentOption) => paymentOption.accountNumber === field.value,
      );

    // If the user has selected a bank account that already exists we return that value.
    if (isExistingBankAccount) {
      return field.value;
    }

    // If the bank account number is defined but not in the list of payment options we return other.
    if (field.value) {
      return otherBankAccountOption;
    }

    // If there are no payment options we return other.
    if (data!.paymentOptions.length === 0) {
      return otherBankAccountOption;
    }

    // We default to an empty string if the bank account number is not defined.
    return '';
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<string>(
    getInitialPaymentOption,
  );

  const handleChanged = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedPaymentOption(event.target.value);

    // Clear the bank account number if the user selects other bank account.
    if (event.target.value === otherBankAccountOption) {
      setFieldValue(`payments[${paymentIndex}].bankAccountNumber`, '');
      helper.setTouched(false, false);
    } else {
      setFieldValue(
        `payments[${paymentIndex}].bankAccountNumber`,
        event.target.value,
      );
    }
  };

  // Clear the selected payment option and bank account number if the user changes the payment placement.
  useEffect(() => {
    if (paymentPlacementField.value !== OrderPaymentType.BankAccount) {
      setSelectedPaymentOption('');
      setFieldValue(`payments[${paymentIndex}].bankAccountNumber`, '');
    }
  }, [paymentPlacementField.value]);

  return (
    <WithGap gap="32">
      {data!.paymentOptions.length > 0 && (
        <Row>
          <Column $span={5}>
            <Select
              name="redemption-payment-option"
              id={`redemption-payment-options-${paymentCurrency}`}
              label={fmsWithTemplate(texts.orders.form.payment.paymentOptions, {
                currency: paymentCurrency,
              })}
              options={options}
              placeholder={texts.orders.form.payment.placeholderRedemption}
              fluid
              onChange={(event) => {
                handleChanged(event);
              }}
              value={selectedPaymentOption}
              disabled={
                paymentPlacementField.value !== OrderPaymentType.BankAccount
              }
              error={
                selectedPaymentOption !== otherBankAccountOption &&
                meta.touched &&
                meta.error
              }
            />
          </Column>
        </Row>
      )}

      {selectedPaymentOption === otherBankAccountOption && (
        <RedemptionBankAccountNumber paymentIndex={paymentIndex} />
      )}
    </WithGap>
  );
};
