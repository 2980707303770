import React from 'react';
import styled from 'styled-components';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { useTexts } from '@/hooks/useTexts';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { FundOrder } from '@/pages/Order/types/Order';

import { TableRowCell } from './TableRowCell';

interface Props {
  orderPaymentType: OrderPaymentType;
  bankAccountNumber?: string;
  portfolioBankAccountNumber?: string;
  noBorder: boolean;
}

export const PaymentRowCell: React.FC<Props> = ({
  orderPaymentType,
  bankAccountNumber,
  portfolioBankAccountNumber,
  noBorder,
}) => {
  const { orders } = useTexts();

  const { orderType } = useOrderPageContext<FundOrder>();

  if (
    orderType !== OrderType.Subscription &&
    orderType !== OrderType.Redemption
  ) {
    throw new Error(`Order type [${orderType}] does not support payment.`);
  }

  const textMap = {
    [OrderType.Subscription]: {
      [OrderPaymentType.Portfolio]: `${orders.form.payment.options.subscription.portfolioAccount} - ${portfolioBankAccountNumber}`,
      [OrderPaymentType.BankAccount]:
        orders.form.payment.options.subscription.settlementAccount,
      [OrderPaymentType.None]: '',
    },
    [OrderType.Redemption]: {
      [OrderPaymentType.Portfolio]: `${orders.form.payment.options.redemption.portfolioAccount} - ${portfolioBankAccountNumber}`,
      [OrderPaymentType.BankAccount]: `${orders.form.payment.options.redemption.externalAccount} - ${bankAccountNumber}`,
      [OrderPaymentType.None]: '',
    },
  };

  return (
    <StyledTableCell noBorder={noBorder}>
      {textMap[orderType][orderPaymentType]}
    </StyledTableCell>
  );
};

const StyledTableCell = styled(TableRowCell)`
  text-align: right;
`;
