import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { apiClientCreator } from '@/api/axios/apiClientCreator';
import { ApiError } from '@/api/types/ApiError';

import { ApiRequestConfig } from '../types/ApiRequestConfig';
import { UnknownApiError } from '../types/UnknownApiError';
import { isAxiosError } from './isAxiosErrorTypeGuard';

export type RequestConfig = ApiRequestConfig & AxiosRequestConfig;

type Response<TData = unknown> =
  | {
      response: AxiosResponse<TData>;
      error?: never;
    }
  | {
      response?: never;
      error: ApiError | UnknownApiError;
    };

export const axiosRequest = async <TData>(
  requestConfig: RequestConfig,
): Promise<Response<TData>> => {
  try {
    const api = apiClientCreator({
      useKeycloakToken: !requestConfig.disableKeycloakToken,
    });

    const isRelativeUrl = requestConfig.url?.startsWith('/');

    const config: RequestConfig = {
      ...requestConfig,
      baseURL:
        (requestConfig.baseURL ?? isRelativeUrl)
          ? window.config.connectBaseApiUrl
          : undefined,
    };

    return {
      response: await api.request<TData>(config),
    };
  } catch (error) {
    if (isAxiosError(error)) {
      return {
        error: new ApiError(
          {
            status: error.response?.status || 0,
            method: error.config?.method || 'N/A',
            url: error.config?.url || 'N/A',
            message: error.message,
            code: error.code,
          },
          error,
        ),
      };
    }

    const unknownError = {
      error: new UnknownApiError(
        {
          message: `Unknown error in axiosRequest: [${
            (error as Error).message
          }]`,
          url: requestConfig.url || 'N/A',
          errorObject: error,
        },
        error,
      ),
    };

    // Network errors are automatically logged to the console. Since this
    // error might not be network related we log it to the console
    // to make it easier to debug.
    console.error(unknownError);

    return unknownError;
  }
};
