import { FC } from 'react';
import React from 'react';

import { SumByCurrency } from '@/api/portfolioGroupHoldings/models/SumByCurrency';
import { WithGap } from '@/components/WithGap';
import { useUserLocale } from '@/i18n/hooks';
import { CurrencyCode } from '@/types/CurrencyCode';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

interface Props {
  sumByCurrency: SumByCurrency;
}

export const SumByCurrencyCell: FC<Props> = ({ sumByCurrency }) => {
  const locale = useUserLocale();

  return (
    <WithGap gap="16">
      {(Object.keys(sumByCurrency) as CurrencyCode[]).map((currency) => (
        <span key={currency}>
          {formatNumber(
            roundHalfEven(sumByCurrency[currency]!, 0),
            locale,
            currency,
          )}
        </span>
      ))}
    </WithGap>
  );
};
