import Button from '@storeblocks/button';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import {
  formatDateArgument,
  useFetchPortfolioPerformanceQuery,
} from '@/api/performanceData/performanceDataApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { UnitChips } from '@/components/Charts/components/UnitChips';
import { DateFilterType } from '@/components/DateFilter/types/DateFilterType';
import { getDateFilter } from '@/components/DateFilter/utils/getDateFilter';
import { FixedHeight } from '@/components/FixedHeight';
import { Helper } from '@/components/Helper';
import { InternalLink } from '@/components/InternalLink';
import { PortfolioPerformanceGraph } from '@/components/PortfolioPerformanceGraph/PortfolioPerformanceGraph';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { Unit } from '@/types/Unit';

import { DateFilterChips } from './DateFilterChips';
import { PortfolioReturnText } from './PortfolioReturnText';

interface Props {
  portfolioGroup: string;
  showMoreLink?: boolean;
}

const startOfTime = new Date(0);
const today = new Date();

export const PortfolioPerformanceWidget: FC<Props> = ({
  portfolioGroup,
  showMoreLink,
}) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();

  const [selectedUnit, setSelectedUnit] = useState(Unit.Percent);
  const [selectedPeriod, setSelectedPeriod] = useState(DateFilterType.ThisYear);

  const dateFilter = getDateFilter(selectedPeriod);

  const portfolioPerformanceQuery = useFetchPortfolioPerformanceQuery({
    portfolioGroup,
    from: formatDateArgument(dateFilter.from ?? startOfTime),
    to: formatDateArgument(dateFilter.to ?? today),
  });

  return (
    <WithGap data-test={`portfolio-widget-performance-${portfolioGroup}`}>
      <WithGap gap="8" direction="row">
        <H2>{texts.widgets.portfolioPerformance.graph.title}</H2>

        <Helper
          id="portfolio-performance-graph"
          title={texts.widgets.portfolioPerformance.graph.help.title}
          description={
            texts.widgets.portfolioPerformance.graph.help.description
          }
        />
      </WithGap>

      <FilterContainer>
        <DateFilterChips
          id="portfolio-performance"
          checked={selectedPeriod}
          onChange={setSelectedPeriod}
        />

        <UnitChips
          id="portfolio-performance"
          checked={selectedUnit}
          onChange={setSelectedUnit}
        />
      </FilterContainer>

      <FixedHeight
        height={
          /* Graph */ 360 + /* <PortfolioReturnText /> */ 24 + /* Gap */ 24
        }
      >
        {isNotReady(portfolioPerformanceQuery) ? (
          <QueryState query={portfolioPerformanceQuery} />
        ) : (
          <WithGap>
            <PortfolioReturnText
              portfolioPerformance={portfolioPerformanceQuery.data!}
              unit={selectedUnit}
            />

            <PortfolioPerformanceGraph
              height={360}
              unit={selectedUnit}
              trackId={`portfolio-widget-performance-${portfolioGroup}`}
              data={
                portfolioPerformanceQuery.data!.performanceMap[selectedUnit]
              }
            />
          </WithGap>
        )}
      </FixedHeight>
      {showMoreLink && (
        <div>
          <Button
            variant="text"
            as={InternalLink}
            to={`/${organizationCmId}/performance`}
            // Due to Storeblocks Button not having properly implemented the
            // `as` prop, we need to use the `@ts-ignore` directive here to
            // be able to use props from the "as" component.
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            state={{ portfolioGroup }}
            iconRight="arrow-right"
            data-trackid={getTrackId(
              `go-to-performance-dashboard-${portfolioGroup}`,
              TrackingElement.Link,
            )}
          >
            {texts.widgets.portfolioPerformance.goToPortfolioDashboard}
          </Button>
        </div>
      )}
    </WithGap>
  );
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
