import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { EsgCurrencyCategory } from '@/api/esg/models/EsgCurrencyCategory';
import { Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { CurrencyCode } from '@/types/CurrencyCode';

import { EsgCarbonFootprintComparison } from './EsgCarbonFootprintComparison';

interface Props {
  esgCarbonIntensities: EsgCurrencyCategory[];
  pai11GhgEmissionsSum: EsgCategory | undefined;
}

export const EsgCarbonFootprint: React.FC<Props> = ({
  esgCarbonIntensities,
}) => {
  const texts = useTexts();

  const carbonFootprintData = esgCarbonIntensities.find(
    (score) => score.currency === CurrencyCode.NOK,
  );

  return (
    <WithGap>
      <Paragraph>{texts.pages.esg.carbonFootprint.description}</Paragraph>

      {carbonFootprintData?.benchmark && (
        <EsgCarbonFootprintComparison data={carbonFootprintData} />
      )}
    </WithGap>
  );
};
