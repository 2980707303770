import Select, { SelectChangeEvent } from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { useDiscretionaryPortfolios } from '../../hooks/useDiscretionaryPortfolios';
import { TransferOrderFormValues } from '../../types/TransferOrderFormValues';
import { createPortfolioBankAccountLabel } from '../../utils/createPortfolioBankAccountLabel';

export enum TransferDirection {
  To = 'to',
  From = 'from',
}

/**
 * Form field names for the 'to' and 'from' transfer directions
 * for portfolio bank account number and currency.
 */
const formFieldName = {
  [TransferDirection.To]: {
    portfolioBankAccountNumber: nameof<TransferOrderFormValues>(
      'toPortfolioBankAccountNumber',
    ),
    currency: nameof<TransferOrderFormValues>('toCurrency'),
  },
  [TransferDirection.From]: {
    portfolioBankAccountNumber: nameof<TransferOrderFormValues>(
      'fromPortfolioBankAccountNumber',
    ),
    currency: nameof<TransferOrderFormValues>('fromCurrency'),
  },
};

interface Props {
  portfolioId: string;
  transferDirection: TransferDirection;
}

export const TransferPortfolioBankAccountsSelect: React.FC<Props> = ({
  portfolioId,
  transferDirection,
}) => {
  const texts = useTexts();

  const { setFieldValue } = useFormikContext<TransferOrderFormValues>();

  const [portfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === portfolioId,
  );

  const portfolioBankAccounts = (portfolio.portfolioBankAccounts || []).map(
    (account) => ({
      label: createPortfolioBankAccountLabel(account),
      value: account.accountNumber,
    }),
  );

  const [field, meta] = useField<string>(
    formFieldName[transferDirection].portfolioBankAccountNumber,
  );

  const handlePortfolioBankAccountChanged = async (
    event: SelectChangeEvent,
  ): Promise<void> => {
    const selectedBankAccount = portfolio.portfolioBankAccounts.find(
      (portfolioBankAccount) =>
        portfolioBankAccount.accountNumber === event.target.value,
    )!;

    // Set the bank account number.
    await setFieldValue(
      formFieldName[transferDirection].portfolioBankAccountNumber,
      event.target.value,
    );

    // Set the currency.
    await setFieldValue(
      formFieldName[transferDirection].currency,
      selectedBankAccount.currency,
    );
  };

  const hint = {
    [TransferDirection.To]:
      texts.orders.form.toPortfolioBankAccountSelect.transfer.hint,
    [TransferDirection.From]:
      texts.orders.form.fromPortfolioBankAccountSelect.transfer.hint,
  }[transferDirection];

  if (!hint) {
    throw new Error(`Invalid portfolioBankAccount key: ${transferDirection}`);
  }

  return (
    <Select
      {...field}
      id={`transfer-${transferDirection}-portfolio-bank-account-selector`}
      label={texts.orders.form.portfolioBankAccountSelect.transfer.title}
      hint={hint}
      placeholder={
        texts.orders.form.portfolioBankAccountSelect.transfer.placeholder
      }
      options={portfolioBankAccounts}
      error={meta.touched && meta.error}
      value={field.value || ''}
      onChange={handlePortfolioBankAccountChanged}
      fluid
    />
  );
};
