import Alert from '@storeblocks/alert';
import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC, useState } from 'react';

import { PortfolioGroup, usePortfoliosQuery } from '@/api/portfolios';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { PortfolioGroupSelect } from '@/components/PortfolioGroupSelect';
import { Ingress } from '@/components/Typography';
import {
  AssetAllocationDonutWidget,
  AssetAllocationTableWidget,
  AssetAllocationViewMode,
} from '@/components/Widgets/AssetAllocation';
import { AssetReturnWidget } from '@/components/Widgets/AssetReturn';
import { PortfolioHoldingsTableWidget } from '@/components/Widgets/PortfolioHoldings';
import { PortfolioPerformanceWidget } from '@/components/Widgets/Portfolios';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';

export const PerformanceDashboardPage: FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();
  const query = usePortfoliosQuery();

  const [portfolioGroup, setPortfolioGroup] = useState<PortfolioGroup>(
    query.data!.portfolioGroups.at(0)!,
  );

  return (
    <CenteredPageContent data-test="performance-dashboard">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink
          to={`/${organizationCmId}`}
          data-trackid={getTrackId('go-to-home', TrackingElement.Link)}
        >
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink
          to={`/${organizationCmId}/performance`}
          data-trackid={getTrackId(
            'go-to-performance-dashboard',
            TrackingElement.Link,
          )}
        >
          {texts.menu.links.performance}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.performanceDashboard.title} />
        {
          // Only show performance if the organization has any portfolio groups.
          query.data!.portfolioGroups.length > 0 ? (
            <>
              {
                // If the organization only has one portfolio group, we don't need to show the portfolio group select.
                query.data!.portfolioGroups.length === 1 ? (
                  <Ingress>{`${texts.pages.dashboard.portfolio.title} ${portfolioGroup.id} - ${portfolioGroup.name}`}</Ingress>
                ) : (
                  <Row>
                    <Column $span={5}>
                      <PortfolioGroupSelect
                        selectedPortfolioGroupId={portfolioGroup.id}
                        onChange={(portfolioGroupId) =>
                          setPortfolioGroup(
                            query.data!.portfolioGroupById.get(
                              portfolioGroupId,
                            )!,
                          )
                        }
                      />
                    </Column>
                  </Row>
                )
              }

              <WithGap
                gap="48"
                data-test={`performance-dashboard-${portfolioGroup.id}`}
              >
                <PortfolioPerformanceWidget
                  portfolioGroup={portfolioGroup.id}
                />

                <AssetReturnWidget portfolioGroup={portfolioGroup.id} />

                <AssetAllocationTableWidget
                  portfolioGroupShortName={portfolioGroup.id}
                  defaultViewMode={AssetAllocationViewMode.Performance}
                />

                <AssetAllocationDonutWidget
                  portfolioGroupShortName={portfolioGroup.id}
                />

                {isBetaFeaturesOn() && (
                  <PortfolioHoldingsTableWidget
                    portfolioGroupId={portfolioGroup.id}
                  />
                )}
              </WithGap>
            </>
          ) : (
            <>
              <Alert
                title={texts.performanceDashboard.noPortfolios}
                variant="info"
              />
            </>
          )
        }
      </MainContent>
    </CenteredPageContent>
  );
};
