import { DateRange } from '@storeblocks/datepicker';
import sub from 'date-fns/sub';
import React, { FC, useState } from 'react';
import { styled } from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { usePortfoliosQuery } from '@/api/portfolios/portfoliosApi';
import { useTransactionsQuery } from '@/api/transactions/transactionsApi';
import { isNotReady } from '@/api/utils/isNotReady';

import { WithGap } from '../WithGap';
import { TableHeader } from './TableHeader';
import { TransactionRow } from './TransactionRow';
import { TransactionsTableFilter } from './TransactionsTableFilter';

const initialToDate = new Date();
const initialFromDate = sub(initialToDate, {
  months: 1,
});

export const TransactionsTable: FC = () => {
  const [dateRange, setDateRange] = useState<DateRange>([
    initialFromDate,
    initialToDate,
  ]);

  const portfoliosQuery = usePortfoliosQuery();

  const [selectedPortfolioGroupId, setSelectedPortfolioGroupId] =
    useState<string>(portfoliosQuery.data!.portfolioGroups[0].id);

  const transactionsQuery = useTransactionsQuery({
    portfolioId: selectedPortfolioGroupId,
    fromDate: dateRange[0]!,
    toDate: dateRange[1]!,
  });

  return (
    <WithGap gap="16">
      <TransactionsTableFilter
        selectedPortfolioGroupId={selectedPortfolioGroupId}
        dateRange={dateRange}
        onSelectedPortfolioChange={setSelectedPortfolioGroupId}
        onDateRangeChange={setDateRange}
      />
      <StyledTable
        cellSpacing={0}
        cellPadding={0}
        data-test="transactions-table"
      >
        <TableHeader />
        {isNotReady(transactionsQuery) ? (
          <QueryState query={transactionsQuery} />
        ) : (
          transactionsQuery.data!.map((transaction) => (
            <TransactionRow
              key={transaction.transactionNumber}
              transaction={transaction}
            />
          ))
        )}
      </StyledTable>
    </WithGap>
  );
};

const StyledTable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
`;
