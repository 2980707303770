import {
  DepositOrder,
  FundOrder,
  TransferOrder,
  WithdrawalOrder,
} from '@/api/order/models/Order';
import { useGetOrderByIdQuery } from '@/api/order/orderApi';
import { useConsumeQueryParameter } from '@/util/useConsumeQueryParameter';

interface Data {
  order: FundOrder | DepositOrder | WithdrawalOrder | TransferOrder;
  signUrl: string;
}

interface Response {
  data: Data | undefined;
  isFetching: boolean;
}

/** Fetch existing order from orderId if provided as query parameter. */
export const useExistingOrder = (): Response => {
  const orderId = useConsumeQueryParameter('orderId');

  const { data, isError, isFetching, error } = useGetOrderByIdQuery(
    { orderId: orderId! },
    { skip: !orderId },
  );

  if (isError) {
    throw new Error(`Could not fetch order with ID [${orderId}].`, {
      cause: error,
    });
  }

  return {
    data,
    isFetching,
  };
};
