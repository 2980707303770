import { Form, Formik } from 'formik';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { Show } from '@/components/Show';
import { H3 } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { useOrderSteps } from '@/pages/Order/components/OrderStepsProvider';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { FundOrder } from '@/pages/Order/types/Order';

import { useFundPortfolios } from '../../hooks/useFundPortfolios';
import { SwitchOrderFormValues } from '../../types/SwitchOrderFormValues';
import { mapRedemptionFundStatesToOrderPlacements } from '../../utils/mapRedemptionFundStatesToOrderPlacements';
import { mapSubscriptionFundStatesToOrderPlacements } from '../../utils/mapSubscriptionFundStatesToOrderPlacements';
import { useCreateSwitchValidationSchema } from '../../validationSchemas/hooks/useCreateSwitchValidationSchema';
import { useInitialSwitchFormValues } from './hooks/useInitialSwitchFormValues';
import { SwitchFormContent } from './SwitchFormContent';

export const SwitchOrderForm: React.FC = () => {
  const { orders } = useTexts();
  const userInfo = useCurrentUserInfo();

  const { completeCurrentStep } = useOrderSteps();
  const { setCurrentOrder } = useOrderPageContext<FundOrder>();

  const portfolios = useFundPortfolios(OrderType.Redemption);
  const hasNoPortfoliosWithHoldings = portfolios.length === 0;

  const initialValues = useInitialSwitchFormValues();
  const validationSchema = useCreateSwitchValidationSchema();

  const handleSubmit = (values: SwitchOrderFormValues): void => {
    const fromOrderPlacements = mapRedemptionFundStatesToOrderPlacements(
      values.fromFunds,
      [],
    );

    const toOrderPlacements = mapSubscriptionFundStatesToOrderPlacements(
      values.toFunds,
      [],
    );

    const fundOrder: FundOrder = {
      orderType: OrderType.Switch,
      userId: userInfo.userCmId || userInfo.signature!,
      portfolioId: values.fromPortfolioShortName,
      transferToPortfolioNumber: values.toPortfolioShortName,
      signatories: [values.signer, values.cosigner].filter(
        (value) => value !== undefined && value !== null,
      ),
      signingProvider: values.signingProvider,
      payments: [],
      orderPlacements: [...fromOrderPlacements, ...toOrderPlacements],
    };

    setCurrentOrder(fundOrder);
    completeCurrentStep();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form data-test="switch-order-form">
        <Show when={hasNoPortfoliosWithHoldings}>
          <H3 data-test="no-holdings-to-sell">
            {orders.form.redemption.noHoldingsToSell}
          </H3>
        </Show>
        <SwitchFormContent />
      </Form>
    </Formik>
  );
};
