import Checkbox from '@storeblocks/checkbox';
import { useField } from 'formik';
import React from 'react';

import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { nameof } from '@/util/nameof';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

import { FormValues } from '../types/FormValues';

export const ConfirmAuthorizedToSign: React.FC = () => {
  const texts = useTexts();

  const [field, meta] = useField<boolean>(nameof<FormValues>('confirmed'));

  const organizationCmId = useOrganizationCmId();
  const { organizationName } = useOrganizationName(organizationCmId);

  return (
    <Checkbox
      {...field}
      id="confirm-authorized-to-sign"
      data-test="confirm-authorized-to-sign"
      options={[
        {
          id: 'CONFIRM',
          value: 'CONFIRM',
          label: fmsWithTemplate(
            texts.orders.signatureRight.confirmSignatureRight.title,
            {
              organization: prettifyOrganizationName(organizationName),
            },
          ),
        },
      ]}
      error={meta.touched && meta.error ? meta.error : undefined}
    />
  );
};
