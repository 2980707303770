import * as Yup from 'yup';
import { type RequiredStringSchema } from 'yup/lib/string';

interface ErrorMessages {
  signerRequired: string;
}

/**
 * Validate that the signer is set.
 */
export const signerFieldValidationSchema = (
  errorMessages: ErrorMessages,
): RequiredStringSchema<string | undefined | null> =>
  Yup.string().nullable().required(errorMessages.signerRequired);
