import * as Yup from 'yup';

import { SigningProvider } from '@/api/order/models/SigningProvider';
import { UserType } from '@/config/types';

interface ErrorMessages {
  signingProviderRequired: string;
}

/**
 * Validate that a signing provider is selected if the user is internal.
 */
export const signingProviderFieldValidationSchema = (
  userType: UserType,
  errorMessages: ErrorMessages,
): Yup.BaseSchema<SigningProvider> => {
  // If the user is external, we don't need to validate the signing provider.
  if (userType === UserType.External) {
    return Yup.mixed().optional();
  }

  return Yup.mixed()
    .oneOf(Object.values(SigningProvider))
    .required(errorMessages.signingProviderRequired);
};
