import last from 'lodash/last';
import { matchRoutes } from 'react-router';

import { MenuItem } from '@/components/Navbar/Menu/types/MenuItem';
import {
  HasPermission,
  useHasPermission,
} from '@/components/Permissions/useHasPermission';

import { connectRoutes, NotFoundPageId } from '../connectRoutes';

interface Return {
  (menuItems: MenuItem[]): MenuItem[];
}

export const useFilterMenuItemsByPermission = (): Return => {
  const hasPermission = useHasPermission();

  return (menuItems) => filterMenuItemsByPermission(menuItems, hasPermission);
};

/**
 * Filter the specified menu items by the user's permissions.
 *
 * @returns The menu items that the user has permission to navigate to.
 */
function filterMenuItemsByPermission(
  menuItems: MenuItem[],
  hasPermission: HasPermission,
): MenuItem[] {
  const filteredMenuItems = menuItems.reduce((filtered, menuItem) => {
    // Find the route object for the menu item.
    const matchedRoutes = matchRoutes(connectRoutes(), menuItem.to);
    const filteredRoutes = (matchedRoutes || []).filter(
      (route) => !route.route.index,
    );
    const routeLast = last(filteredRoutes)!;
    const route = routeLast.route;

    if (route.id === NotFoundPageId) {
      // The route was not found for the given menu item path.
      // This means that the path was not found in the routes, i.e.
      // the user does not have permission to navigate to the path.
      return filtered;
    }

    if (
      !route.permissions ||
      hasPermission({ mustHave: 'all', permissions: route.permissions })
    ) {
      const filteredItem = { ...menuItem };
      if (menuItem.children) {
        filteredItem.children = filterMenuItemsByPermission(
          menuItem.children,
          hasPermission,
        );
      } else {
        // Explicitly set that this menu item has no children.
        filteredItem.children = undefined;
      }
      filtered.push(filteredItem);
    }

    return filtered;
  }, [] as MenuItem[]);

  return filteredMenuItems.filter((menuItem) => {
    // Menu items that usually have children, but have no children after
    // filtering, should be removed.
    return menuItem.children === undefined || menuItem.children.length > 0;
  });
}
