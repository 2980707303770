import { AssetPerformanceData } from '@/api/performanceData/models';

/**
 * Traverse the node tree to find all container nodes.
 * A container node is a node with depth 1, meaning that the
 * node only has one level of child nodes.
 * @param node The node to traverse.
 * @returns An array of all descendant container nodes.
 */
export const getContainerNodes = (
  node: AssetPerformanceData,
): Array<AssetPerformanceData> => {
  // :? If the node depth is 1, this is a container node.
  if (node.nodeDepth === 1) {
    return [node];
  }

  // Traverse the children and add all child container nodes to the array.
  return node.assets.reduce(
    (mergedContainerNodes: AssetPerformanceData[], childNode) => {
      const containerNodesForNode = getContainerNodes(childNode);
      containerNodesForNode.forEach((containerNode) => {
        mergedContainerNodes.push(containerNode);
      });

      return mergedContainerNodes;
    },
    [] as AssetPerformanceData[],
  );
};
