import sumBy from 'lodash/sumBy';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { SubscriptionFundState } from '../../types/SubscriptionFundState';

interface ErrorMessages {
  toPercentDoesNotAddUpTo100: string;
}

export const testSwitchToTotalPercent = (
  context: Yup.TestContext<AnyObject>,
  errorMessages: ErrorMessages,
  toFunds: Array<SubscriptionFundState> | undefined,
): true | Yup.ValidationError => {
  const totalToPercent = sumBy(
    toFunds,
    (fund: SubscriptionFundState) => fund.value,
  );

  // Show validation message if the total to percent
  // does not add up to 100%.
  if (totalToPercent !== 100) {
    return context.createError({
      message: errorMessages.toPercentDoesNotAddUpTo100,
    });
  }

  return true;
};
