import React from 'react';
import styled from 'styled-components';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { EsgCurrencyCategory } from '@/api/esg/models/EsgCurrencyCategory';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';
import { CurrencyCode } from '@/types/CurrencyCode';

import { EsgValueListItem } from './EsgValueListItem';

interface Props {
  esgScore: EsgCategory;
  solutionCompanies: EsgCategory;
  euTaxonomyAlignment: EsgCategory;
  euTaxonomyEligibility: EsgCategory;
  carbonIntensities: EsgCurrencyCategory[];
  pai11GhgEmissionsSum: EsgCategory | undefined;
}

export const EsgOverviewValueList: React.FC<Props> = ({
  esgScore,
  euTaxonomyAlignment,
  euTaxonomyEligibility,
  carbonIntensities,
}) => {
  const fms = useTexts();

  const texts = fms.pages.esg.overview;

  const carbonFootprintData = carbonIntensities.find(
    (score) => score.currency === CurrencyCode.NOK,
  );

  const carbonFootprintDifference = carbonFootprintData?.difference ?? 0;

  const emissionsText =
    carbonFootprintDifference <= 0
      ? texts.lowerEmissions
      : texts.higherEmissions;

  return (
    <ValueList>
      {carbonFootprintData?.benchmark && (
        <EsgValueListItem
          description={texts.carbonFootprint}
          value={carbonFootprintData?.difference}
          unit={`% ${emissionsText}`}
        />
      )}

      <EsgValueListItem
        description={texts.esgScore}
        value={esgScore.kpi.value}
        unit="/10"
      />

      {/** @todo: Temporary taxonomy data need to be hidden, see SAMIK-1799 */}
      <Show when={false}>
        <EsgValueListItem
          description={texts.taxonomyEligibility}
          value={euTaxonomyEligibility?.kpi?.value}
          unit="%"
        />
        <EsgValueListItem
          description={texts.taxonomyAlignment}
          value={euTaxonomyAlignment?.kpi?.value}
          unit="%"
        />
      </Show>
    </ValueList>
  );
};

const ValueList = styled.ul`
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  list-style-type: none;
`;
