import last from 'lodash/last';
import React from 'react';
import styled from 'styled-components';

import { PortfolioPerformance } from '@/api/performanceData/models';
import { Unit } from '@/types/Unit';

import { DateIntervalText } from './DateIntervalText';
import { ReturnInAmountText } from './ReturnInAmountText';
import { ReturnInPercentText } from './ReturnInPercentText';
import { YieldIcon } from './YieldIcon';

interface Props {
  portfolioPerformance: PortfolioPerformance;
  unit: Unit;
}

export const PortfolioReturnText: React.FC<Props> = ({
  portfolioPerformance,
  unit,
}) => {
  const returnInAmount =
    last(
      portfolioPerformance.performanceMap[Unit.Amount].dailyReturnAccumulated,
    )?.[1] ?? 0;

  const returnInPercent =
    last(
      portfolioPerformance.performanceMap[Unit.Percent].dailyReturnAccumulated,
    )?.[1] ?? 0;

  return (
    <Container>
      <DateIntervalText
        fromDate={portfolioPerformance.firstPointDate}
        toDate={portfolioPerformance.lastPointDate}
      />

      <YieldIcon direction={returnInAmount < 0 ? 'down' : 'up'} />

      {unit === Unit.Amount && <ReturnInAmountText amount={returnInAmount} />}

      {unit === Unit.Percent && (
        <ReturnInPercentText percent={returnInPercent} />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
