import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC, useState } from 'react';

import { QueryState } from '@/api/components/QueryState';
import {
  useOrganizationEsgQuery,
  usePortfolioGroupEsgQuery,
} from '@/api/esg/esgApi';
import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import {
  allPortfoliosKey,
  PortfolioGroupSelect,
} from '@/components/PortfolioGroupSelect';
import { Ingress } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { EsgDashboard } from './EsgDashboard';

/**
 * A page displaying ESG dashboard for the whole organization or of a single portfolio group.
 */
export const EsgPage: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const [activePortfolioGroupId, setActivePortfolioGroupId] =
    useState(allPortfoliosKey);

  const portfoliosQuery = usePortfoliosQuery();
  const hasMultiplePortfolioGroups =
    (portfoliosQuery.data?.portfolioGroups.length ?? 0) > 1;

  // Fetch data for the selected portfolio group.
  const portfolioEsgQuery = usePortfolioGroupEsgQuery(
    { portfolioGroupId: activePortfolioGroupId },

    // Only fetch data when a specific portfolio group is selected.
    { skip: activePortfolioGroupId === allPortfoliosKey },
  );

  // Fetch ESG data for the whole organization.
  const organizationEsgQuery = useOrganizationEsgQuery();

  const esgData =
    activePortfolioGroupId === allPortfoliosKey
      ? organizationEsgQuery.data
      : portfolioEsgQuery.currentData;

  // Downstream components require data from these queries.
  // They are fetched here to have only one loading state for the whole page.
  const fundListQuery = useFundListQuery();
  const holdingsQuery = useFetchFundHoldingsQuery();

  if (
    isNotReady([
      portfoliosQuery,
      organizationEsgQuery,
      fundListQuery,
      holdingsQuery,
    ])
  ) {
    return (
      <CenteredPageContent>
        <QueryState
          query={[
            portfoliosQuery,
            organizationEsgQuery,
            fundListQuery,
            holdingsQuery,
          ]}
        />
      </CenteredPageContent>
    );
  }

  return (
    <CenteredPageContent data-test="reports-esg-page">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability`}>
          {texts.menu.links.reports.sustainability}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/sustainability/esg`}>
          {texts.menu.links.reports.esg}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.reports.esg} />

        {
          // The organization has multiple portfolios.
          // Display a selector to choose which portfolio group to display.
          hasMultiplePortfolioGroups && (
            <Row>
              <Column $span={5}>
                <PortfolioGroupSelect
                  onChange={(portfolioGroupId) => {
                    setActivePortfolioGroupId(portfolioGroupId);
                  }}
                  selectedPortfolioGroupId={activePortfolioGroupId}
                  withAllOption
                />
              </Column>
            </Row>
          )
        }

        {
          // A specific portfolio group is selected.
          // Display loading state when data for the portfolio group is fetching.
          (portfolioEsgQuery.isFetching || portfolioEsgQuery.isError) && (
            <QueryState query={portfolioEsgQuery} />
          )
        }

        {
          // Render the dashboard when the data is ready.
          esgData && (
            <WithGap>
              {
                // Display the portfolio name when the organization has only one portfolio.
                !hasMultiplePortfolioGroups && (
                  <Ingress>
                    {`${texts.pages.dashboard.portfolio.title} ${portfoliosQuery.data!.portfolioGroups[0].id} - ${portfoliosQuery.data!.portfolioGroups[0].name}`}
                  </Ingress>
                )
              }

              <EsgDashboard data={esgData} />
            </WithGap>
          )
        }
      </MainContent>
    </CenteredPageContent>
  );
};
