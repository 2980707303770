import sumBy from 'lodash/sumBy';
import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';

import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Locale } from '@/i18n/locale';
import { getValueFromPercent } from '@/pages/Order/utils/getValueFromPercent';
import { CurrencyCode } from '@/types/CurrencyCode';
import { formatNumber } from '@/util/formatNumber';

import { RedemptionFundState } from '../../types/RedemptionFundState';
import { SubscriptionFundState } from '../../types/SubscriptionFundState';
import { SwitchOrderFormValues } from '../../types/SwitchOrderFormValues';

interface ErrorMessages {
  minimumSubscriptionAmount: string;
  redemptionAndSubscriptionAmountIsNotEqual: string;
}

export const testSwitchFromAmountToPercentTotal = (
  context: Yup.TestContext<AnyObject>,
  locale: Locale,
  errorMessages: ErrorMessages,
  toFunds: Array<SubscriptionFundState> | undefined,
): true | Yup.ValidationError => {
  const formValues: SwitchOrderFormValues = context.parent;

  // If there are any funds with sellAll, do not validate total amount.
  if (formValues.fromFunds.some((fund: RedemptionFundState) => fund.sellAll)) {
    return true;
  }

  const totalFromAmount = sumBy(
    formValues.fromFunds,
    (fund: RedemptionFundState) => fund.value,
  );

  let totalToAmount = 0;
  const errors: Array<Yup.ValidationError> = [];

  (toFunds || []).forEach((fund: SubscriptionFundState) => {
    if (fund.value) {
      const toFundAmount = getValueFromPercent(fund.value, totalFromAmount);

      if (toFundAmount < fund.minimumSubscriptionAmount) {
        const formattedAmount = formatNumber(
          fund.minimumSubscriptionAmount,
          locale,
          CurrencyCode[formValues.currency!],
        );

        errors.push(
          context.createError({
            message: fmsWithTemplate(errorMessages.minimumSubscriptionAmount, {
              amount: formattedAmount,
            }),
          }),
        );
      }

      totalToAmount += toFundAmount;
    }
  });

  // Display validation message if the total calculated to
  // amount does not match the total from amount.
  if (totalFromAmount !== totalToAmount) {
    errors.push(
      context.createError({
        message: errorMessages.redemptionAndSubscriptionAmountIsNotEqual,
      }),
    );
  }

  // Return all errors if present
  if (errors.length > 0) {
    return new Yup.ValidationError(errors);
  }

  return true;
};
