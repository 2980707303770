import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import { useUserSettingsQuery } from '@/api/userSettings/userSettingsApi';
import { FMSTexts } from '@/types/fms';

export const TextsContext = createContext<FMSTexts | undefined>(undefined);

/**
 * Make the texts available to the application.
 */
export const TextsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const userSettings = useUserSettingsQuery();

  const fmsTextsQuery = useFmsTextsQuery(userSettings.data!.language);

  const [fmsTexts, setFmsTexts] = useState<FMSTexts | undefined>(
    fmsTextsQuery.data,
  );

  useEffect(() => {
    // Update the FMS texts when the user changes language.
    if (fmsTextsQuery.data) {
      setFmsTexts(fmsTextsQuery.data);
    }
  }, [fmsTextsQuery.data]);

  return (
    <TextsContext.Provider value={fmsTexts}>{children}</TextsContext.Provider>
  );
};
