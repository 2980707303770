import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';
import { useUserDateFnsLocale, useUserLocale } from '@/i18n/hooks';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { DepositOrder } from '@/pages/Order/types/Order';
import { formatNumber } from '@/util/formatNumber';

import { useDiscretionaryPortfolios } from '../../OrderDetails/hooks/useDiscretionaryPortfolios';
import { createPortfolioBankAccountLabel } from '../../OrderDetails/utils/createPortfolioBankAccountLabel';
import { expectedDepositDate } from '../../OrderDetails/utils/expectedDepositDate';
import { MainHeaderRow } from './MainHeaderRow';
import { PortfolioRow } from './PortfolioRow';
import { Row } from './Row';

export const DepositOrderDetailsTable: React.FC = () => {
  const texts = useTexts();
  const locale = useUserLocale();
  const dateFnsLocale = useUserDateFnsLocale();

  const { currentOrder } = useOrderPageContext<DepositOrder>();

  const [portfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === currentOrder!.portfolioId,
  );

  const [portfolioBankAccount] = portfolio.portfolioBankAccounts.filter(
    (account) =>
      account.accountNumber === currentOrder!.toPortfolioBankAccountNumber,
  );

  return (
    <StyledTable cellSpacing="0" cellPadding="0">
      <thead>
        <MainHeaderRow title={texts.orders.form.table.orderDetails} />
      </thead>

      <tbody>
        <PortfolioRow portfolioShortName={currentOrder!.portfolioId} />

        <Row
          left={texts.orders.form.table.portfolioBankAccount}
          right={createPortfolioBankAccountLabel(portfolioBankAccount)}
        />

        <Row
          left={texts.orders.form.table.amount}
          right={formatNumber(
            currentOrder!.amount,
            locale,
            currentOrder!.currency,
          )}
        />

        <Row
          left={texts.orders.form.table.date}
          right={format(
            currentOrder!.expectedDepositDate,
            expectedDepositDate.dateFormat(dateFnsLocale),
          )}
        />

        <Row
          left={texts.orders.form.table.messageToSam}
          right={
            currentOrder!.messageToSam?.trim() ||
            texts.orders.form.table.messageNotSpecified
          }
        />
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  line-height: 16px;
  width: 100%;
`;
