import { CurrencyCode } from '@/types/CurrencyCode';

import { FundHoldingsDto } from '../dtos';
import { FundHoldings } from '../models';

/**
 * Flattens the holdings from the API response.
 *
 * The holdings are nested in portfolioGroup -> portfolio -> account -> holdings.
 * Thus flatten the holdings to a single flat array.
 */
export const flattenHoldings = (portfolio: FundHoldingsDto): FundHoldings[] => {
  return portfolio.accounts.reduce((accumulatedFundHoldings, account) => {
    const fundHoldings: FundHoldings[] = account.holdings.map((holding) => ({
      name: holding.fundName,
      isin: holding.isin,
      units: holding.units,
      nav: holding.nav.amount,
      marketValue: holding.marketValue.amount,
      currency:
        CurrencyCode[holding.marketValue.currency as keyof typeof CurrencyCode],
      accountId: account.accountId,
      portfolioShortName: portfolio.portfolioShortName,
      portfolioGroupId: portfolio.portfolioGroupShortName,
    }));

    return [...accumulatedFundHoldings, ...fundHoldings];
  }, [] as FundHoldings[]);
};
