import sortBy from 'lodash/sortBy';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';

import { SubscriptionPayment } from '../../../types/Payment';
import { SignatoryOption } from '../../../types/SignatoryOption';

export const createInitialSubscriptionValues = (
  fundStates: SubscriptionFundState[],
  portfolioShortName: string,
  toValueType: OrderValueType,
  signatoryOption: SignatoryOption,
  paymentPlacement?: SubscriptionPayment,
): SubscriptionOrderFormValues => {
  const sortedFundStates = sortBy(fundStates, (fund) =>
    fund.name.toLowerCase(),
  );

  return {
    toPortfolioShortName: portfolioShortName,
    toFunds: sortedFundStates,
    payments: [paymentPlacement].filter((payment) => !!payment),
    toValueType,
    signer: null,
    cosigner: undefined,
    signatoryOption,
    signingProvider: undefined,
  };
};
