import { Option } from '@storeblocks/checkbox';

import { useTexts } from '@/hooks/useTexts';
import { presentationScreenRoutes } from '@/routes/presentationScreenRoutes';

import { getScreenTitle } from '../utils/getScreenTitle';

export const selectAllOptionValue = 'select-all-screens';

export const useScreenOptions = (): Array<Option> => {
  const texts = useTexts();

  return presentationScreenRoutes.map((route) => ({
    id: route.id!,
    value: route.id!,
    label: getScreenTitle(texts, route.id!),
  }));
};
