import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { useValidateInvitationMutation } from '@/api/users/usersApi';
import { FullPageLoadingIndicator } from '@/components/FullPageLoadingIndicator';
import { createLogEvent } from '@/logging/createLogEvent';
import { addLogEvent } from '@/logging/logSlice';
import { AppDispatch } from '@/store';

const NO_UUID = 'no_uuid';

export const InvitationalLinkLandingPage: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [validateInvitation, validateInvitationRequest] =
    useValidateInvitationMutation();

  const uuid = getUuidFromUrl(location.pathname);

  // Log the user's UUID and validate the user.
  useEffect(() => {
    dispatch(
      addLogEvent(
        createLogEvent({
          level: 'info',
          type: 'other',
          description: 'New user - parsed UUID from URL',
          data: {
            uuid,
          },
        }),
      ),
    );

    if (uuid !== NO_UUID) {
      validateInvitation({ invitationId: uuid });
    }
  }, [uuid]);

  // The user has been validated successfully. Redirect to the success page.
  useEffect(() => {
    if (validateInvitationRequest.isSuccess) {
      dispatch(
        addLogEvent(
          createLogEvent({
            level: 'info',
            type: 'other',
            description: 'New user - response from validation request',
            data: {
              uuid: validateInvitationRequest.originalArgs!.invitationId,
              state: 'success',
            },
          }),
        ),
      );

      navigate(`/newuser/${uuid}/success`);
    }
  }, [validateInvitationRequest.isSuccess]);

  // The user has not been validated successfully. Redirect to the error page.
  useEffect(() => {
    if (validateInvitationRequest.isError) {
      dispatch(
        addLogEvent(
          createLogEvent({
            level: 'info',
            type: 'other',
            description: 'New user - response from validation request',
            data: {
              uuid: validateInvitationRequest.originalArgs!.invitationId,
              state: 'error',
            },
          }),
        ),
      );

      navigate(`/newuser/${uuid}/error`);
    }
  }, [validateInvitationRequest.isError]);

  return (
    <div data-test="invitation-landing-page">
      <FullPageLoadingIndicator />
    </div>
  );
};

function getUuidFromUrl(pathname: string): string {
  const splitted = pathname.split('/');

  if (splitted.length > 2) {
    return splitted[2];
  }

  return NO_UUID;
}
