import { isAfter, isValid, min } from 'date-fns';

interface CutOff {
  isPastCutOff: boolean;
  cutOffTime: Date;
}

/**
 * Get the earliest cut-off time from the given cut-off times.
 *
 * @param cutOffTimes Cut-off times for funds. All internal funds should have a
 * cut off value. However some external funds might not have a specified cut-off time.
 *
 * @returns `undefined` if there are no cut-off times, otherwise the earliest cut-off time.
 */
export const getEarliestCutOffTime = (
  cutOffTimes: (Date | undefined)[],
): CutOff | undefined => {
  const earliestCutOff = min(
    [...cutOffTimes]
      // The cut off time can be undefined for some external funds.
      // All internal funds should have a cut off value.
      .filter((time): time is Date => isValid(time)),
  );

  if (isValid(earliestCutOff)) {
    return {
      isPastCutOff: isAfter(new Date(), earliestCutOff),
      cutOffTime: earliestCutOff,
    };
  }

  return undefined;
};
