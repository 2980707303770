import React, { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { Permission } from '@/api/permissions/models/Permission';
import { SigningRightStatus } from '@/api/signing-right/models/SigningRight';
import { useSigningRightForUserQuery } from '@/api/signing-right/signingRightApi';
import { ApiError } from '@/api/types/ApiError';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useHasPermission } from '@/components/Permissions/useHasPermission';

export const RequireSigningRight: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const hasPermission = useHasPermission();
  const organizationCmId = useOrganizationCmId();

  // Signing right should only be checked if the user has order sign permission.
  const hasOrderSignPermission =
    hasPermission(Permission.OrdersSign) ||
    hasPermission(Permission.OrdersSignMissingSigningRight);

  const signingRightQuery = useSigningRightForUserQuery(undefined, {
    skip: !hasOrderSignPermission,
  });

  // `navigate` must be used in a `useEffect`.
  useEffect(() => {
    // Redirect to the signing right form page if the user dose not have signing right.
    if (signingRightQuery.data?.status === SigningRightStatus.NotStarted) {
      navigate(`/${organizationCmId}/trading/signing-right`, {
        replace: true,
      });
    }

    // Redirect to the in-progress page if the user has not
    // cancelled nor signed the signing right.
    if (signingRightQuery.data?.status === SigningRightStatus.InProgress) {
      navigate(`/${organizationCmId}/trading/signing-right/in-progress`, {
        replace: true,
      });
    }

    // Redirect to the unconfirmed page if the user has signed
    // but we have not yet received signing confirmation.
    if (signingRightQuery.data?.status === SigningRightStatus.Unconfirmed) {
      navigate(`/${organizationCmId}/trading/signing-right/unconfirmed`, {
        replace: true,
      });
    }

    // Redirect to the expired page if the signing has expired.
    if (signingRightQuery.data?.status === SigningRightStatus.Expired) {
      navigate(`/${organizationCmId}/trading/signing-right/expired`, {
        replace: true,
      });
    }
  }, [signingRightQuery.data?.status]);

  if (signingRightQuery.isError) {
    throw new Error(
      `Failed to fetch signing right, with error message [${
        (signingRightQuery.error as ApiError).message
      }].`,
    );
  }

  if (signingRightQuery.isLoading) {
    // The HTTP call should be lightening fast, so don't render anything.
    return null;
  }

  // The user does not have order sign permission, bypass signing right and render the children.
  if (!hasOrderSignPermission) {
    return <>{children}</>;
  }

  // The user has signing right, we can render the children.
  if (signingRightQuery.data!.status === SigningRightStatus.Completed) {
    return <>{children}</>;
  }

  // The user has not completed the signing right process.
  // The code paths in the `useEffect` will handle navigation to the
  // proper pages. Don't render anything until navigation has completed.
  return null;
};
