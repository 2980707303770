import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUserSettingsQuery, util } from '@/api/userSettings/userSettingsApi';
import { useCurrentUserInfo } from '@/auth/hooks';
import { UserType } from '@/config/types';
import { Language } from '@/i18n/Language';
import { AppDispatch } from '@/store';

import { UserSettings } from '../models/UserSettings';

const defaultUserSettings: UserSettings = {
  emailBusiness: '',
  language: 'nb' as Language,
  lastUpdated: undefined,
  updateNeeded: false,
};

interface Response {
  userSettings: UserSettings | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: unknown;
}

/**
 * Fetch and return the current user's settings.
 */
export const useCurrentUserSettings = (): Response => {
  const userInfo = useCurrentUserInfo();
  const dispatch = useDispatch<AppDispatch>();

  const [isDispatched, setIsDispatched] = useState(false);

  /**
   * Only fetch user settings if the user type is external
   * or if the user is logged in to an organization.
   * This is required due to JettyWar only accepting tokens
   * with a CMID when fetching user settings.
   */
  const shouldSkipQuery =
    userInfo.userType === UserType.Internal && !userInfo.currentOrgCmId;

  const {
    data: userSettings,
    isSuccess,
    isLoading,
    isError,
    error,
  } = useUserSettingsQuery(undefined, {
    skip: shouldSkipQuery,
  });

  useEffect(() => {
    const updateCache = async (): Promise<void> => {
      await dispatch(
        util.upsertQueryData('userSettings', undefined, defaultUserSettings),
      );

      setIsDispatched(true);
    };

    if (shouldSkipQuery) {
      updateCache();
    }
  }, [shouldSkipQuery]);

  if (shouldSkipQuery) {
    return {
      userSettings: defaultUserSettings,
      isSuccess: isDispatched,
      isLoading: !isDispatched,
      isError,
      error,
    };
  }

  return {
    userSettings,
    isSuccess,
    isLoading,
    isError,
    error,
  };
};
