import * as Yup from 'yup';

import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Locale } from '@/i18n/locale';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { CurrencyCode } from '@/types/CurrencyCode';
import { formatNumber } from '@/util/formatNumber';

import { baseSubscriptionFundStateValidationSchema } from './baseSubscriptionFundStateValidationSchema';

interface ErrorMessages {
  minimumSubscriptionAmountMessage: string;
}

export const subscriptionInAmountFundStateValidationSchema = (
  locale: Locale,
  { minimumSubscriptionAmountMessage }: ErrorMessages,
): Yup.SchemaOf<SubscriptionFundState> =>
  baseSubscriptionFundStateValidationSchema.concat(
    Yup.object({
      value: Yup.number()
        .test(function validateSubscriptionAmount(value) {
          // Don't show validation message when there isn't any amount.
          if (!value) {
            return true;
          }

          const fundState: SubscriptionFundState = this.parent;

          // Show validation message if the value is negative.
          if (value < 0) {
            return this.createError({
              message: 'Value cannot be negative',
            });
          }

          // Show validation message when the amount is less
          // than the minimum subscription amount.
          if (value < fundState.minimumSubscriptionAmount) {
            const formattedAmount = formatNumber(
              fundState.minimumSubscriptionAmount,
              locale,
              CurrencyCode[fundState.currency],
            );

            return this.createError({
              message: fmsWithTemplate(minimumSubscriptionAmountMessage, {
                amount: formattedAmount,
              }),
            });
          }
          return true;
        })
        .required(),
    }),
  );
