import { useCurrentUserInfo } from '@/auth/hooks';

import { useOrderPageContext } from '../OrderPageContext';

export const useIsCurrentUserOwner = (): boolean => {
  const { currentOrder } = useOrderPageContext();
  const userInfo = useCurrentUserInfo();

  return (
    !currentOrder ||
    currentOrder.userId === (userInfo.userCmId || userInfo.signature)
  );
};
