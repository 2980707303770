import * as Yup from 'yup';

import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Locale } from '@/i18n/locale';
import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';
import { CurrencyCode } from '@/types/CurrencyCode';
import { formatNumber } from '@/util/formatNumber';

import { baseRedemptionFundStateValidationSchema } from './baseRedemptionFundStateValidationSchema';

interface ErrorMessages {
  minimumRedemptionAmountMessage: string;
  marketValueNotAbove95percentMessage: string;
}

/** Validate redemption in Amount */
export const redemptionInAmountFundStateValidationSchema = (
  locale: Locale,
  {
    minimumRedemptionAmountMessage,
    marketValueNotAbove95percentMessage,
  }: ErrorMessages,
): Yup.SchemaOf<RedemptionFundState> =>
  baseRedemptionFundStateValidationSchema.concat(
    Yup.object({
      value: Yup.number()
        .test(function validateRedemptionAmount(value) {
          // Don't show validation message when there isn't any amount.
          if (!value) {
            return true;
          }

          const fundState: RedemptionFundState = this.parent;

          // Don't show validation message when selling all.
          if (fundState.sellAll) {
            return true;
          }

          // Show validation message if the value is negative.
          if (value < 0) {
            return this.createError({
              message: 'Value cannot be negative',
            });
          }

          // Show validation message if the value is less than the minimum amount.
          if (value < fundState.minimumRedemptionAmount) {
            const formattedAmount = formatNumber(
              fundState.minimumRedemptionAmount,
              locale,
              CurrencyCode[fundState.currency],
            );

            return this.createError({
              message: fmsWithTemplate(minimumRedemptionAmountMessage, {
                amount: formattedAmount,
              }),
            });
          }

          // Show validation message if the value exceeds 95% of the fund holdings market value.
          if (value > fundState.marketValue95percent) {
            return this.createError({
              message: marketValueNotAbove95percentMessage,
            });
          }

          return true;
        })
        .required(),
    }),
  );
