import HighchartsReact from 'highcharts-react-official';
import React, { memo } from 'react';

import { Unit } from '@/types/Unit';

import { useHighcharts } from '../highcharts';
import { DonutChartData } from './types/DonutChartData';
import { useDonutChartOptions } from './useDonutChartOptions';

interface Props {
  data: DonutChartData;
  unit: Unit;
  size?: number;
  trackId?: string;
  positionLegendBottom?: boolean;
  className?: string;
}

/**
 * Highcharts `pie` chart implementation to display a donut chart.
 * @param {DonutChartData} data - Data to be rendered in the chart.
 * @param {Unit} unit - Unit displayed in chart.
 * @param {number} [size=200] - The size of the chart in pixels. Defaults to `200`.
 * @param {string} [trackId] - GTM id for interaction tracking.
 * @param {string} [positionLegendBottom] - Position the legend to the bottom of the donut.
 * @param {string} [className] - Used for styling `styled-components`.
 */
export const DonutChart: React.FC<Props> = memo(
  ({
    data,
    unit,
    size = 200,
    positionLegendBottom = false,
    className,
    trackId,
  }: Props) => {
    const highcharts = useHighcharts();

    const options = useDonutChartOptions(
      data,
      size,
      unit,
      positionLegendBottom,
    );

    return (
      <HighchartsReact
        highcharts={highcharts}
        options={options}
        containerProps={{ className, 'data-chartid': trackId }}
        immutable
      />
    );
  },
);

DonutChart.displayName = DonutChart.name;
