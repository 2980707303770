import Breadcrumbs from '@storeblocks/breadcrumbs';
import Button from '@storeblocks/button';
import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useOrderListQuery } from '@/api/orderList/orderListApi';
import { useDailyTradeReportsQuery } from '@/api/reports/reportsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { ContractNotesTable } from '@/components/ContractNotes/ContractNotesTable';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { PushRight } from '@/components/PushRight';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { Line } from './components/Line';
import { NoActiveOrders } from './components/NoActiveOrders';
import { OrderList } from './OrderList';

export const OrderListPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  const orderListQuery = useOrderListQuery();
  const dailyTradeReportsQuery = useDailyTradeReportsQuery();

  if (
    isNotReady([orderListQuery, dailyTradeReportsQuery], { skipNoData: true })
  ) {
    return <QueryState query={[orderListQuery, dailyTradeReportsQuery]} />;
  }

  return (
    <WithGap gap="24" data-test="order-list-page">
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/trading`}>
          {texts.menu.links.trading.trading}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/trading/orders`}>
          {texts.menu.links.trading.orderList}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading
          header={texts.pages.orderList.header.title}
          ingress={texts.pages.orderList.header.description}
        />

        <Line />

        <WithGap gap="80">
          {orderListQuery.data!.length > 0 ? <OrderList /> : <NoActiveOrders />}

          {dailyTradeReportsQuery.data!.length > 0 && (
            <WithGap gap="32">
              <H2>{texts.trading.history.contractNotes.title}</H2>

              <ContractNotesTable
                contractNotes={dailyTradeReportsQuery.data!.slice(0, 5)}
              />

              <PushRight>
                <Button
                  variant="text"
                  iconRight="arrow-right"
                  as={InternalLink}
                  to={`/${organizationCmId}/reports/contract-notes`}
                  data-trackid={getTrackId(
                    'go-to-contract-notes',
                    TrackingElement.Link,
                  )}
                >
                  {texts.pages.orderList.contractNotes.goToContractNotes.title}
                </Button>
              </PushRight>
            </WithGap>
          )}
        </WithGap>
      </MainContent>
    </WithGap>
  );
};
