import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { RedemptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/RedemptionOrderFormValues';
import { FundOrder } from '@/pages/Order/types/Order';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { isRedemptionPayment } from '../../../types/Payment';
import { getDefaultSignatoryOption } from '../../../utils/getDefaultSignatoryOption';
import { getSignatoryOptionFromSignatories } from '../../../utils/getSignatoryOptionFromSignatories';
import { getValueTypeFromOrderPlacements } from '../../../utils/getValueTypeFromOrderPlacements';
import { mapHoldingsToRedemptionFundState } from '../../../utils/mapHoldingsToRedemptionFundState';
import { createInitialRedemptionValues } from '../utils/createInitialRedemptionValues';
import { createRedemptionFundStates } from '../utils/createRedemptionFundStates';

export const useInitialRedemptionFormValues = (): RedemptionOrderFormValues => {
  const { data: fundList } = useFundListQuery();
  const { data: fundHoldings } = useFetchFundHoldingsQuery();
  const { currentOrder } = useOrderPageContext<FundOrder>();
  const portfolios = useFundPortfolios(OrderType.Redemption);
  const hasPermission = useHasPermission();
  const { userCmId: currentUserCmId } = useCurrentUserInfo();

  // Convert an existing fundOrder to order form values if it exists in the state.
  if (currentOrder) {
    const portfolioHoldings =
      fundHoldings!.byPortfolioId[currentOrder.portfolioId];

    const fromValueType = getValueTypeFromOrderPlacements(
      currentOrder.orderPlacements,
    );

    // Replace the original portfolio fund state with the one from order
    // placement since it has more state.
    const redemptionFundStates = createRedemptionFundStates(
      currentOrder.orderPlacements,
      portfolioHoldings,
      fundList!,
      fromValueType,
    );

    const payments = currentOrder.payments.filter(isRedemptionPayment);

    const orderFormValues: RedemptionOrderFormValues = {
      fromPortfolioShortName: currentOrder.portfolioId,
      fromFunds: redemptionFundStates,
      payments,
      signer: currentOrder.signatories[0] ?? null,
      cosigner: currentOrder.signatories[1],
      signatoryOption: getSignatoryOptionFromSignatories(
        currentOrder.signatories,
        currentUserCmId ?? '-1',
        hasPermission,
      ),
      signingProvider: currentOrder.signingProvider,
      fromValueType,
    };

    return orderFormValues;
  }

  const portfolioShortName = portfolios.length === 1 ? portfolios[0].id : '';

  const fundStates = portfolioShortName
    ? mapHoldingsToRedemptionFundState(
        fundList!.byIsin,
        fundHoldings!.byPortfolioId[portfolioShortName].holdings,
        OrderValueType.Amount,
      )
    : [];

  return createInitialRedemptionValues(
    fundStates,
    portfolioShortName,
    OrderValueType.Amount,
    getDefaultSignatoryOption(hasPermission),
  );
};
