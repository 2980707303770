import { createApi } from '@reduxjs/toolkit/query/react';
import { unflatten } from 'flat';
import { isEmpty } from 'lodash';

import { Language } from '@/i18n/Language';
import { FMSTexts } from '@/types/fms';

import { axiosBaseQuery, RtkBaseQueryResponse } from '../axios/axiosBaseQuery';
import { RequestConfig } from '../axios/axiosRequest';
import { getBackupFmsTexts } from './utils/getBackupFmsTexts';
import { getFmsLanguage } from './utils/getFmsLanguage';
import { getFmsUrl } from './utils/getFmsUrl';

/**
 * Fetch the texts for the application.
 *
 * This fetches the texts for the application for the specified language.
 * The texts are stored and fetched from FMS (Fragment Management System).
 * This is the i18n solution for Storebrand.
 *
 * If the FMS endpoint is not available, the backup texts are used. These texts
 * are stored in the backend of the application and are used as a fallback.
 */
export const fmsApi = createApi({
  reducerPath: 'fmsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fmsTexts: builder.query<FMSTexts, Language>({
      queryFn: async (
        language,
        _queryApi,
        _extraOptions,
        fetchWithBQ: (
          args: RequestConfig,
        ) => Promise<RtkBaseQueryResponse<FMSTexts>>,
      ) => {
        const fmsLanguage = getFmsLanguage(language);

        // Fetch the text from FMS.
        const response = await fetchWithBQ({
          url: getFmsUrl(fmsLanguage),
          disableKeycloakToken: true, // No authentication is required for this endpoint.
          timeout: 5000,
        });

        if (response.error || isEmpty(response.data)) {
          // If the FMS endpoint was not available, or we got a response with
          // no data, use the backup texts.
          const backupTexts = await getBackupFmsTexts(language);

          return {
            data: unflatten({ ...backupTexts }) as FMSTexts,
          };
        }

        return {
          data: unflatten(response.data) as FMSTexts,
        };
      },
    }),
  }),
});

export const { useFmsTextsQuery } = fmsApi;
