import * as Yup from 'yup';
import { type RequiredStringSchema } from 'yup/lib/string';

interface ErrorMessages {
  portfolioRequired: string;
}

export const portfolioNameFieldValidationSchema = (
  errorMessages: ErrorMessages,
): RequiredStringSchema<string | undefined> =>
  Yup.string().required(errorMessages.portfolioRequired);
