import { useFormikContext } from 'formik';
import { groupBy } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { SubscriptionPayment } from '../../../types/Payment';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';

/**
 * Add payment options to the subscription form based on the funds that has an amount.
 */
export const useSubscriptionPaymentOptions = (): void => {
  const formContext = useFormikContext<SubscriptionOrderFormValues>();

  const { data: fundList } = useFundListQuery();

  const portfolios = useFundPortfolios(OrderType.Subscription);

  const hasPortfolioBankAccounts =
    getPortfolioBankAccounts(
      formContext.values.toPortfolioShortName,
      portfolios,
    ).length > 0;

  const defaultPlacement = hasPortfolioBankAccounts
    ? // Let the user choose how to pay.
      // Through the portfolio bank account (porteføljekonto) or with a bank transfer.
      OrderPaymentType.None
    : // The user has only the option to use bank account transfer.
      OrderPaymentType.BankAccount;

  const fundsWithValueIsins = formContext.values.toFunds
    .filter((fund) => fund.value > 0)
    .map((fund) => fund.isin);

  useDeepCompareEffect(() => {
    // Find the selected funds from the fund list.
    const fundsWithValue = fundList!.funds.filter((fund) =>
      fundsWithValueIsins.includes(fund.isin),
    );

    // Group the funds by their payment information.
    // This will give us a list of funds that have the same payment information.
    // We only need to display one payment option for each unique payment information.
    const fundByPayment = groupBy(
      fundsWithValue,
      (fund) =>
        // A payment option is unique by its bank account number and currency.
        `${fund.paymentInformation!.bankAccountNumber}-${fund.paymentInformation!.currency}`,
    );

    // Create the payment options that will be displayed to the user.
    const paymentOptions = Object.entries(
      fundByPayment,
    ).map<SubscriptionPayment>(([, funds]) => {
      const fundPaymentInformation = funds[0].paymentInformation!;

      // Use the existing payment option if it exists to preserve its state.
      const existingPayment = formContext.values.payments.find(
        (payment) =>
          // A payment option is unique by its bank account number and currency.
          payment.fundPaymentInformation.currency ===
            fundPaymentInformation.currency &&
          payment.fundPaymentInformation.bankAccountNumber ===
            fundPaymentInformation.bankAccountNumber,
      );

      if (existingPayment) {
        return {
          ...existingPayment,

          // Add the new funds to the existing payment.
          forIsins: funds.map((fund) => fund.isin),
        };
      }

      return {
        type: 'SUBSCRIPTION_PAYMENT',

        paymentPlacement: defaultPlacement,

        portfolioBankAccountNumber: undefined,

        fundPaymentInformation: {
          accountHolder: fundPaymentInformation.accountHolder,
          currency: fundPaymentInformation.currency,
          bankAccountNumber: fundPaymentInformation.bankAccountNumber,
          iban: fundPaymentInformation.iban,
          swift: fundPaymentInformation.swift,
        },

        forIsins: funds.map((fund) => fund.isin),
      };
    });

    formContext.setFieldValue('payments', paymentOptions);
  }, [fundsWithValueIsins]);
};
