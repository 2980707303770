import { useEffect } from 'react';

export function useKeyPress(
  onKeyPressed: (key: string) => void,
  keys: string[],
  deps: unknown[] = [],
): void {
  function downHandler({ key }: KeyboardEvent): void {
    if (keys.includes(key)) {
      onKeyPressed(key);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, deps);
}
