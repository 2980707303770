import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { FundOrder } from '@/pages/Order/types/Order';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { SubscriptionOrderFormValues } from '../../../types/SubscriptionOrderFormValues';
import { getDefaultSignatoryOption } from '../../../utils/getDefaultSignatoryOption';
import { getSignatoryOptionFromSignatories } from '../../../utils/getSignatoryOptionFromSignatories';
import { getValueTypeFromOrderPlacements } from '../../../utils/getValueTypeFromOrderPlacements';
import { mapHoldingsToSubscriptionFundState } from '../../../utils/mapHoldingsToSubscriptionFundState';
import { createInitialSubscriptionValues } from '../utils/createInitialSubscriptionValues';
import { createSubscriptionFundStates } from '../utils/createSubscriptionFundStates';
import { mapOrderPlacementToPayments } from '../utils/mapOrderPlacementToPayments';

export const useInitialSubscriptionFormValues =
  (): SubscriptionOrderFormValues => {
    const { data: fundList } = useFundListQuery();
    const { data: fundHoldings } = useFetchFundHoldingsQuery();
    const { currentOrder } = useOrderPageContext<FundOrder>();
    const portfolios = useFundPortfolios(OrderType.Subscription);
    const { userCmId: currentUserCmId } = useCurrentUserInfo();
    const hasPermission = useHasPermission();

    // Convert an existing fundOrder to order form values if it exists in the state.
    if (currentOrder) {
      const portfolioHoldings =
        fundHoldings!.byPortfolioId[currentOrder.portfolioId];

      const toValueType = getValueTypeFromOrderPlacements(
        currentOrder.orderPlacements,
      );

      const subscriptionFundStates = createSubscriptionFundStates(
        currentOrder.orderPlacements,
        portfolioHoldings,
        fundList!,
        toValueType,
      );

      const payments = mapOrderPlacementToPayments(
        currentOrder.orderPlacements,
        fundList!,
      );

      const orderFormValues: SubscriptionOrderFormValues = {
        toPortfolioShortName: currentOrder.portfolioId,
        toFunds: subscriptionFundStates,
        payments,
        signer: currentOrder.signatories[0] ?? null,
        cosigner: currentOrder.signatories[1],
        signatoryOption: getSignatoryOptionFromSignatories(
          currentOrder.signatories,
          currentUserCmId ?? '-1',
          hasPermission,
        ),
        signingProvider: currentOrder.signingProvider,
        toValueType,
      };

      return orderFormValues;
    }

    const portfolioShortName = portfolios.length === 1 ? portfolios[0].id : '';

    const fundStates = portfolioShortName
      ? mapHoldingsToSubscriptionFundState(
          fundList!.byIsin,
          fundHoldings!.byPortfolioId[portfolioShortName].holdings,
          OrderValueType.Amount,
        )
      : [];

    // Make it easier for the customer if they only have one portfolio.
    // Set it to that so they don't have to choose it.
    return createInitialSubscriptionValues(
      fundStates,
      portfolioShortName,
      OrderValueType.Amount,
      getDefaultSignatoryOption(hasPermission),
    );
  };
