import Alert from '@storeblocks/alert';
import Radio, { Option as RadioOption } from '@storeblocks/radio';
import Select, { Option as SelectOption } from '@storeblocks/select';
import { format } from 'date-fns';
import { useField } from 'formik';
import React, { FC, useEffect } from 'react';

import { SigningProvider } from '@/api/order/models/SigningProvider';
import { Permission } from '@/api/permissions/models/Permission';
import { useSignatoriesForOrganizationQuery } from '@/api/signing-right/signingRightApi';
import { useUserSettings } from '@/api/userSettings/utils/useUserSettings';
import { useCurrentUserInfo } from '@/auth/hooks';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { WithGap } from '@/components/WithGap';
import { UserType } from '@/config/types';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { defaultDateFormat } from '@/i18n/hooks/useUserDateFormat';
import { orderExpiryDate } from '@/util/orderExpiryDate';

import { SignatoryOption } from '../types/SignatoryOption';

const noneOptionValue = 'none';

/**
 * Component for selecting signatory options.
 *
 * When signatory option SELF is selected, the current user is set as signer.
 * When signatory option COSIGNER is selected, the current user is set as signer and a cosigner select is displayed.
 * When signatory option OTHER is selected, a signer and cosigner select is displayed.
 *
 * If the user is internal, an additional select for payment method is displayed.
 */
export const SignatoryOptions: FC = () => {
  const texts = useTexts();
  const { language } = useUserSettings();
  const { userCmId: currentUserCmId, userType } = useCurrentUserInfo();
  const signatoriesQuery = useSignatoriesForOrganizationQuery();

  const hasPermission = useHasPermission();
  const hasOrdersSignPermission = hasPermission(Permission.OrdersSign);

  const [signatoryOptionField, signatoryOptionMeta, signatoryOptionHelpers] =
    useField<SignatoryOption>('signatoryOption');

  const [signerField, signerMeta, signerHelpers] = useField<string | null>(
    'signer',
  );

  const [cosignerField, cosignerMeta, cosignerHelpers] = useField<
    string | undefined
  >('cosigner');

  const [signingProviderField, signingProviderMeta] = useField<
    SigningProvider | undefined
  >('signingProvider');

  const isUserMissingEmailAddress = (cmId: string): boolean =>
    !signatoriesQuery.data?.find((user) => user.cmId === cmId)?.hasEmail;

  const signatoryOptions: RadioOption[] = [
    {
      id: SignatoryOption.Self,
      value: SignatoryOption.Self,
      label: texts.orders.form.signatories.options.self,
      disabled: !hasOrdersSignPermission,
    },
    {
      id: SignatoryOption.Cosigner,
      value: SignatoryOption.Cosigner,
      label: texts.orders.form.signatories.options.cosigner,
      disabled: !hasOrdersSignPermission,
    },
    {
      id: SignatoryOption.Other,
      value: SignatoryOption.Other,
      label: texts.orders.form.signatories.options.other,
    },
  ];

  const signerOptions: SelectOption[] = signatoriesQuery
    .data!.filter(
      // Filter the cosigners to exclude the current user and selected cosigner as an option.
      (signer) =>
        signer.cmId !== currentUserCmId && signer.cmId !== cosignerField.value,
    )
    .sort(
      (a, b) =>
        // Descending order on whether the user is verified or not.
        // Verified users are shown first.
        Number(b.isVerified) - Number(a.isVerified) ||
        // Then ascending order on the name of the user.
        a.name.localeCompare(b.name, language),
      // Thus verified users are shown first and then sorted by name.
    )
    .map((signer) => ({
      label: signer.name,
      value: signer.cmId,
    }));

  const cosignerOptions = [
    // Add option to select no cosigner if the signatory option is set to other.
    signatoryOptionField.value === SignatoryOption.Other && {
      label: texts.orders.form.cosigner.none,
      value: noneOptionValue,
    },
    ...signatoriesQuery
      .data! // Filter the cosigners to exclude the current user and selected signer as an option.
      .filter(
        (cosigner) =>
          cosigner.cmId !== currentUserCmId &&
          cosigner.cmId !== signerField.value,
      )
      .sort(
        (a, b) =>
          // Descending order on whether the user is verified or not.
          // Verified users are shown first.
          Number(b.isVerified) - Number(a.isVerified) ||
          // Then ascending order on the name of the user.
          a.name.localeCompare(b.name, language),
        // Thus verified users are shown first and then sorted by name.
      )
      .map((cosigner) => ({
        label: cosigner.name,
        value: cosigner.cmId,
      })),
  ].filter((options) => options !== false);

  const signingProviderOptions = [
    {
      label: texts.orders.form.signingProvider.nbid,
      value: SigningProvider.NorwegianBankID,
    },
    {
      label: texts.orders.form.signingProvider.sbid,
      value: SigningProvider.SwedishBankID,
    },
  ];

  const handleSignatoryOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const signatoryOption = event.target.value as SignatoryOption;

    // Set the signer to the current user if the signatory option is self or cosigner
    // and clear the selected cosigner.
    if (
      signatoryOption === SignatoryOption.Self ||
      signatoryOption === SignatoryOption.Cosigner
    ) {
      signerHelpers.setValue(currentUserCmId!);
      cosignerHelpers.setValue(undefined);
    }

    // Clear the signer and cosigner if the signatory option is other.
    if (signatoryOption === SignatoryOption.Other) {
      signerHelpers.setValue(null);
      cosignerHelpers.setValue(undefined);
    }

    // Set the signatory option.
    signatoryOptionHelpers.setValue(signatoryOption);
  };

  const handleCosignerChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    // Set the cosigner to undefined if the selected value is none.
    if (event.target.value === noneOptionValue) {
      cosignerHelpers.setValue(undefined);
    } else {
      cosignerHelpers.setValue(event.target.value);
    }
  };

  /** Workaround to ensure that validation runs on signatory option when set manually.
   * This is required because React triggers a re-render before the formik context is updated.
   * This means that the Yup validation triggers with outdated values.
   * We therefore have to manually trigger the validation by setting the field to touched when the value has changed.
   * We only want to do this when the value has changed from Unknown to something else.
   */
  useEffect(() => {
    if (signatoryOptionField.value !== SignatoryOption.Unknown) {
      signatoryOptionHelpers.setTouched(true);
    }
  }, [signatoryOptionField.value]);

  return (
    <WithGap>
      <Radio
        {...signatoryOptionField}
        id="signatory-option"
        data-test="signatory-option"
        label={texts.orders.form.signatories.options.title}
        options={signatoryOptions}
        onChange={handleSignatoryOptionChange}
        error={
          signatoryOptionMeta.touched ? signatoryOptionMeta.error : undefined
        }
      />
      {/* Show signer selector if the current user signatory option is other. */}
      {signatoryOptionField.value === SignatoryOption.Other && (
        <>
          <Select
            {...signerField}
            id="signer-select"
            data-test="signer-select"
            label={texts.orders.form.signer.title}
            placeholder={texts.orders.form.signer.placeholder}
            hint={fmsWithTemplate(texts.orders.form.signer.expiryDate, {
              date: format(
                orderExpiryDate(new Date()),
                `${defaultDateFormat} HH:mm`,
              ),
            })}
            options={signerOptions}
            value={signerField.value || ''}
            error={signerMeta.touched && signerMeta.error}
            large
          />
          {/* Show alert if the selected signer is missing email. */}
          {signerField.value &&
            isUserMissingEmailAddress(signerField.value) && (
              <>
                <Alert
                  id="signer-missing-email"
                  variant="info"
                  title={texts.orders.form.signer.missingEmail.title}
                  description={
                    texts.orders.form.signer.missingEmail.description
                  }
                />
              </>
            )}
        </>
      )}
      {/* Show cosigner selector if signatory option is cosigner or other. */}
      {(signatoryOptionField.value === SignatoryOption.Cosigner ||
        signatoryOptionField.value === SignatoryOption.Other) && (
        <>
          <Select
            {...cosignerField}
            id="cosigner-select"
            data-test="cosigner-select"
            // Display optional text if the signatory option is other.
            label={
              signatoryOptionField.value === SignatoryOption.Cosigner
                ? texts.orders.form.cosigner.title
                : `${texts.orders.form.cosigner.title} (${texts.orders.form.cosigner.optional})`
            }
            placeholder={texts.orders.form.cosigner.placeholder}
            hint={fmsWithTemplate(texts.orders.form.cosigner.expiryDate, {
              date: format(
                orderExpiryDate(new Date()),
                `${defaultDateFormat} HH:mm`,
              ),
            })}
            options={cosignerOptions}
            // Set the value to none if the signatory option is other and no cosigner is selected.
            value={
              cosignerField.value ||
              (signatoryOptionField.value === SignatoryOption.Other
                ? noneOptionValue
                : '')
            }
            onChange={handleCosignerChange}
            error={cosignerMeta.touched && cosignerMeta.error}
            large
          />
          {/* Show alert if the selected co-signer is missing email. */}
          {cosignerField.value &&
            isUserMissingEmailAddress(cosignerField.value) && (
              <>
                <Alert
                  id="cosigner-missing-email"
                  variant="info"
                  title={texts.orders.form.cosigner.missingEmail.title}
                  description={
                    texts.orders.form.cosigner.missingEmail.description
                  }
                />
              </>
            )}
        </>
      )}
      {userType === UserType.Internal && (
        <Select
          {...signingProviderField}
          id="signing-provider"
          data-test="signing-provider-select"
          label={texts.orders.form.signingProvider.label}
          placeholder={texts.orders.form.signingProvider.placeholder}
          options={signingProviderOptions}
          value={signingProviderField.value || ''}
          error={signingProviderMeta.touched && signingProviderMeta.error}
          large
        />
      )}
    </WithGap>
  );
};
