import ChipGroup, { Option } from '@storeblocks/chip/lib/group';
import React, { useState } from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';
import { Brand } from '@/types/Brand';

import { CustomFilterValue } from '../CustomFilterValue';

type FilterValues = (Brand | CustomFilterValue)[];

interface Props {
  options: Option[];
  onFilterChange: (brands: Brand[]) => void;
}

export const BrandFilter: React.FC<Props> = ({ options, onFilterChange }) => {
  const texts = useTexts();
  const [checked, setChecked] = useState<FilterValues>([CustomFilterValue.All]);

  const handleChange = (checkedFilterValues: FilterValues): void => {
    setChecked(checkedFilterValues);

    if (hasOnlyBrands(checkedFilterValues)) {
      onFilterChange(checkedFilterValues);
    } else {
      // Don't filter on any brands.
      onFilterChange([]);
    }
  };

  return (
    <section>
      <Label>{texts.orders.form.addFundModal.filter.brand.title}</Label>
      <ChipGroup
        id="brand-fund-filter"
        type="choice"
        options={options}
        checked={checked}
        onChange={handleChange}
      />
    </section>
  );
};

const Label = styled.label`
  margin-bottom: 16px;
  font-weight: 600;
  display: block;
`;

/**
 * Type guard to know if the array only contains values of `Brand`.
 */
function hasOnlyBrands(filterValues: FilterValues): filterValues is Brand[] {
  return filterValues.every((brand) => Brand[brand as Brand]);
}
