import { parseISO } from 'date-fns';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { FundNavDto } from '../dtos/FundNavDto';
import { FundNavListDto } from '../dtos/FundNavListDto';
import { FundNavList } from '../models/FundNavList';
import { Nav } from '../models/Nav';
import { getMaxFractionDigits } from './getMaxFractionDigits';

export const transformFundNavListResponse = (
  dto: FundNavListDto,
): FundNavList => {
  const clientFunds: Nav[] = dto.clientFunds
    .map((nav) => mapToNav(nav))
    .sort((a, b) => a.fundName.localeCompare(b.fundName));

  const connectFunds: Nav[] = dto.connectFunds
    .map((nav) => mapToNav(nav))
    .sort((a, b) => a.fundName.localeCompare(b.fundName));

  // :: Find the maximum number of fraction digits in the NAV values.
  //
  // This is used to determine the number of decimal places to display in the UI.
  // The default is 4 decimal places.
  //
  // Some funds may have NAV values with more decimal places.
  // If the number of decimal places in the NAV values is greater than 4,
  // then the number of decimal places is set to the maximum number of decimal places
  // in the NAV values.
  const clientFractionDigits = getMaxFractionDigits(
    clientFunds.filter((fund) => fund.hasNav).map((fund) => fund.nav),
  );
  const connectFractionDigits = getMaxFractionDigits(
    connectFunds.filter((fund) => fund.hasNav).map((fund) => fund.nav),
  );

  const fractionDigits = Math.max(
    clientFractionDigits,
    connectFractionDigits,
    4,
  );

  const navList: FundNavList = {
    clientFunds,
    connectFunds,
    maxFractionDigits: fractionDigits,
  };

  return navList;
};

function mapToNav(fund: FundNavDto): Nav {
  if (!fund.nav) {
    return {
      fundName: fund.name,
      isin: fund.isin,
      hasNav: false,
      nav: undefined as never,
      currency: undefined as never,
      navDate: undefined as never,
    };
  }

  return {
    fundName: fund.name,
    isin: fund.isin,
    hasNav: true,
    nav: fund.nav,
    currency: convertStringToEnum(fund.currency!, CurrencyCode),
    navDate: parseISO(fund.date!),
  };
}
