import * as Yup from 'yup';

import { FMSTexts } from '@/types/fms';

interface Directory {
  id: string;
  name: string;
}

export interface EditOrganizationFormValues {
  orgName: string;
  directories: string[];
}

export const getInitialValues = (
  orgName: string,
  directories: Directory[],
): EditOrganizationFormValues => ({
  orgName: orgName || '',
  directories: directories.map((dir) => dir.id) || [],
});

export const getValidationSchema = (
  texts: FMSTexts,
): Yup.SchemaOf<EditOrganizationFormValues> =>
  Yup.object({
    orgName: Yup.string().required(texts.form.emptyMessage),
    directories: Yup.array().of(Yup.string().required()),
  });
