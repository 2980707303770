import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

/**
 * Reset focus to start of the page when the user navigates to a new page.
 */
export const ResetFocus: React.FC = () => {
  const resetFocusRef = useRef<HTMLSpanElement>(null);
  const { pathname } = useLocation();

  useEffect(() => {
    // Reset focus to start of the page when the user navigates to a new page.
    resetFocusRef.current?.focus();
  }, [pathname]);

  return (
    <span
      ref={resetFocusRef}
      // Setting tabIndex to -1 makes the element focusable with code, but not with keyboard navigation.
      tabIndex={-1}
      aria-hidden
    />
  );
};
