import { useCallback, useEffect } from 'react';

export const useKeyDown = (
  key: string,
  onKeyDown: () => void,
  ref: React.MutableRefObject<HTMLElement | null>,
): void => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        onKeyDown();
      }
    },
    [onKeyDown],
  );

  useEffect(() => {
    ref.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      ref.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, ref.current]);
};
