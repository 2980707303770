import React, { FC, useState } from 'react';

import { Permission } from '@/api/permissions/models/Permission';
import { usePortfoliosQuery } from '@/api/portfolios';
import { Column, Row } from '@/components/Grid';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import {
  allPortfoliosKey,
  PortfolioGroupSelect,
} from '@/components/PortfolioGroupSelect';
import { AssetAllocationTableWidget } from '@/components/Widgets/AssetAllocation/AssetAllocationTable/AssetAllocationTableWidget';
import { PortfolioPerformanceWidget } from '@/components/Widgets/Portfolios/PortfolioPerformanceWidget';
import { PortfolioReturnsWidget } from '@/components/Widgets/Portfolios/PortfolioReturnsWidget';
import { LatestMonthlyReportsWidget } from '@/components/Widgets/Reports/LatestMonthlyReports/LatestMonthlyReportsWidget';
import { WithGap } from '@/components/WithGap';
import { OngoingOrdersWidget } from '@/pages/OrderList/OngoingOrdersWidget';

import { EsgOverviewWidget } from './EsgOverviewWidget';
import { NewsArticlesWidget } from './NewsArticlesWidget';
import { TradingLinks } from './TradingLinks';

export const PortfolioDashboard: FC = () => {
  const hasPermission = useHasPermission();

  const query = usePortfoliosQuery();

  const [selectedPortfolio, setSelectedPortfolio] = useState<string>(
    query.data!.portfolioGroups.length === 1
      ? query.data!.portfolioGroups.at(0)!.id
      : allPortfoliosKey,
  );

  const portfolioGroup = query.data!.portfolioGroupById.get(selectedPortfolio)!;

  const portfolioIds = query.data!.portfolios.map((portfolio) => portfolio.id);

  const handleChange = (portfolioGroupId: string): void => {
    setSelectedPortfolio(portfolioGroupId);
  };

  return (
    <>
      {query.data!.portfolioGroups.length > 1 && (
        <Row>
          <Column $span={5}>
            <PortfolioGroupSelect
              onChange={handleChange}
              selectedPortfolioGroupId={selectedPortfolio}
              withAllOption
            />
          </Column>
        </Row>
      )}
      <WithGap gap="48">
        {selectedPortfolio === allPortfoliosKey && <PortfolioReturnsWidget />}

        {selectedPortfolio !== allPortfoliosKey && (
          <PortfolioPerformanceWidget
            portfolioGroup={portfolioGroup.id}
            showMoreLink
          />
        )}

        {hasPermission(Permission.OrdersRead) && <TradingLinks />}

        {selectedPortfolio !== allPortfoliosKey && (
          <AssetAllocationTableWidget
            portfolioGroupShortName={portfolioGroup.id}
          />
        )}

        <EsgOverviewWidget />

        {hasPermission(Permission.ReportsRead) && (
          <LatestMonthlyReportsWidget />
        )}

        {hasPermission(Permission.OrdersRead) && (
          <OngoingOrdersWidget portfolioIds={portfolioIds} />
        )}

        <NewsArticlesWidget />
      </WithGap>
    </>
  );
};
