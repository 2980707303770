import Breadcrumbs from '@storeblocks/breadcrumbs';
import Button from '@storeblocks/button';
import DatePicker from '@storeblocks/datepicker';
import Input from '@storeblocks/input';
import React, { useMemo, useState } from 'react';
import { styled } from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { useFundNavListQuery } from '@/api/funds/fundsApi';
import { FundNavList } from '@/api/funds/models/FundNavList';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useUserDateFnsLocale, useUserLocale } from '@/i18n/hooks';
import { datePickerDateFormat } from '@/util/datePickerDateFormat';

import { NavList } from './NavList';
import { createDownloadLink } from './utils/createDownloadLink';
import { fuzzySearchFundNav } from './utils/fuzzySearchFundNav';

// Stable reference to today's date.
const today = new Date();

export const FundNavPage: React.FC = () => {
  const texts = useTexts();
  const locale = useUserLocale();
  const dateFnsLocale = useUserDateFnsLocale();
  const organizationCmId = useOrganizationCmId();

  const [navDate, setNavDate] = useState(today);
  const fundNavListQuery = useFundNavListQuery({ navOnOrBeforeDate: navDate });

  const handleDateChange = (_: unknown, selectedDate: Date): void => {
    setNavDate(selectedDate);
  };

  return (
    <CenteredPageContent>
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>

        <InternalLink to={`/${organizationCmId}/fund`}>
          {texts.menu.links.fundInformation}
        </InternalLink>

        <InternalLink to={`/${organizationCmId}/fund/fund-nav`}>
          {texts.menu.links.navList}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.navList} />

        <WithGap gap="48">
          <DatePicker
            id="search-by-date"
            name="search-by-date"
            locale={locale}
            label={texts.pages.fundNavList.datePicker.title}
            hint={texts.pages.fundNavList.datePicker.description}
            value={navDate}
            onChange={handleDateChange}
            max={today}
            format={datePickerDateFormat(dateFnsLocale)}
            inputProps={{
              autoComplete: 'off',
              placeholder: datePickerDateFormat(dateFnsLocale),
            }}
          />

          {isNotReady(fundNavListQuery) ? (
            <QueryState query={fundNavListQuery} />
          ) : (
            <Content navList={fundNavListQuery.data!} />
          )}
        </WithGap>
      </MainContent>
    </CenteredPageContent>
  );
};

interface Props {
  navList: FundNavList;
}

const Content: React.FC<Props> = ({ navList }) => {
  const texts = useTexts();

  const [filterQuery, setFilterQuery] = useState('');

  const filteredNavList = useMemo(
    () => fuzzySearchFundNav(filterQuery, navList.connectFunds),
    [filterQuery, navList],
  );

  const clientFundNavDownloadLink = useMemo(
    () => createDownloadLink(navList.clientFunds),
    [navList.clientFunds],
  );

  const connectFundNavDownloadLink = useMemo(
    () => createDownloadLink(filteredNavList),
    [filteredNavList],
  );

  const handleSearch = (query: string): void => {
    setFilterQuery(query);
  };

  return (
    <WithGap gap="48" data-test="fund-nav-page">
      {navList.clientFunds.length !== 0 && (
        <div data-test="nav-holdings">
          <JustifyBetween>
            <Heading>{texts.pages.fundList.fundsInYourPortfolio.title}</Heading>

            <Button
              data-test="fund-nav-client-download"
              variant="outlined"
              iconLeft="download"
              as="a"
              download="nav-holdings.csv"
              href={clientFundNavDownloadLink}
            >
              {texts.pages.fundNavList.download.title}
            </Button>
          </JustifyBetween>
          <NavList
            nav={navList.clientFunds}
            navValuePrecision={navList.maxFractionDigits}
          />
        </div>
      )}

      {navList.connectFunds.length !== 0 && (
        <div data-test="nav-all">
          <Heading>{texts.pages.fundList.allFunds.title}</Heading>

          <WithGap>
            <JustifyBetweenAlignEnd>
              <Wrapper>
                <Input
                  id="fund-nav-list-search-input"
                  name="fund-nav-list-search-input"
                  label={texts.pages.fundList.allFunds.search}
                  value={filterQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  icon="search"
                  fluid
                  inputProps={{
                    autoComplete: 'off',
                  }}
                />
              </Wrapper>

              <Button
                data-test="fund-nav-all-download"
                variant="outlined"
                iconLeft="download"
                as="a"
                download="nav-all.csv"
                href={connectFundNavDownloadLink}
                disabled={connectFundNavDownloadLink === undefined}
              >
                {texts.pages.fundNavList.download.title}
              </Button>
            </JustifyBetweenAlignEnd>

            <NavList
              nav={filteredNavList}
              navValuePrecision={navList.maxFractionDigits}
            />

            {filteredNavList.length === 0 && (
              <p>{texts.searchResults.noResultsFound}</p>
            )}
          </WithGap>
        </div>
      )}
    </WithGap>
  );
};

const JustifyBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

const JustifyBetweenAlignEnd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const Heading = styled(H2)`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  width: 528px;
`;
