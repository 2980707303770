import Breadcrumbs from '@storeblocks/breadcrumbs';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useParams } from 'react-router';

import { QueryState } from '@/api/components/QueryState';
import { useFundEsgQuery } from '@/api/esg/esgApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';

import { EmDash } from './components/EmDash';
import { Line } from './components/Line';
import { EsgCarbonIntensities } from './widgets/EsgCarbonIntensities';
import { EsgScienceBasedTargets } from './widgets/EsgScienceBasedTargets';
import { EsgScore } from './widgets/EsgScore';
import { EsgSolutions } from './widgets/EsgSolutions';

export const EsgFundPage: React.FC = () => {
  const texts = useTexts();

  const dateFormat = useUserDateFormat();

  const { isin } = useParams();
  const organizationCmId = useOrganizationCmId();

  const esgFundQuery = useFundEsgQuery({ isin: isin! });
  const fundListQuery = useFundListQuery();

  if (isNotReady(esgFundQuery) || isNotReady(fundListQuery)) {
    return (
      <CenteredPageContent>
        <QueryState
          query={[esgFundQuery, fundListQuery]}
          customTexts={{ noData: { title: texts.pages.esg.noData } }}
        />
      </CenteredPageContent>
    );
  }

  return (
    <CenteredPageContent data-test="esg-fund-page">
      <AnnouncementWarning />
      <OrderToSignAlert />

      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/fund/fund-list`}>
          {texts.menu.links.fundList}
        </InternalLink>
        <InternalLink
          to={`/${organizationCmId}/sustainability/esg/fund/${esgFundQuery.data!.isin}/${esgFundQuery.data!.fundName}`}
        >
          {esgFundQuery.data!.fundName}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={esgFundQuery.data!.fundName} />

        <WithGap gap="16" direction="row">
          <Hint>
            {fmsWithTemplate(texts.common.lastUpdated, {
              date: format(
                parseISO(esgFundQuery.data!.updatedDate),
                dateFormat,
              ),
            })}
          </Hint>

          <Hint>
            <EmDash />
          </Hint>

          <Hint>{esgFundQuery.data!.isin}</Hint>

          {esgFundQuery.data!.benchmarkName && (
            <>
              <Hint>
                <EmDash />
              </Hint>

              <Hint>
                {fmsWithTemplate(texts.pages.fundEsg.benchmark.name, {
                  name: esgFundQuery.data!.benchmarkName,
                })}
              </Hint>
            </>
          )}
        </WithGap>

        <Line />

        <EsgScore isin={isin!} />

        {esgFundQuery.data!.carbonIntensities.length > 0 && (
          <>
            <Line />
            <EsgCarbonIntensities isin={isin!} />
          </>
        )}

        <Line />

        <EsgSolutions isin={isin!} />

        {esgFundQuery.data!.scienceBasedTargetSet && (
          <>
            <Line />

            <EsgScienceBasedTargets isin={isin!} />
          </>
        )}
      </MainContent>
    </CenteredPageContent>
  );
};
