import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import React from 'react';
import { useNavigate } from 'react-router';

import {
  useCancelSigningRightMutation,
  useSigningRightForUserQuery,
} from '@/api/signing-right/signingRightApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { WithGap } from '@/components/WithGap';
import { isCypress } from '@/config/utils';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const ChangeStatusButtons: React.FC = () => {
  const texts = useTexts();
  const navigate = useNavigate();
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();

  const signingRightQuery = useSigningRightForUserQuery();
  const [cancelSigningRight, cancelSigningRightState] =
    useCancelSigningRightMutation();

  const handleContinueToSigning = (): void => {
    if (isCypress()) {
      // Don't redirect to signing when we are on Cypress tests due to
      // window.location.href redirects the Cypress Chrome instance and not
      // the page under test.
      // Since the redirect URL comes from the POST response, we can can mock
      // the response in Cypress and set our own redirect URL. This way we can
      // skip the signing and go directly to either the success or error page,
      // depending on what we want to test.
      navigate(signingRightQuery.data!.signingUrl);
      return;
    }

    // Redirect to Signicat for signing.
    window.location.href = signingRightQuery.data!.signingUrl;
  };

  const handleCancelSigning = async (): Promise<void> => {
    try {
      // Unwrap throw an error if the request fails.
      await cancelSigningRight().unwrap();

      // Refetch the signing right to update the status after cancelling.
      await signingRightQuery.refetch();

      // Redirect to the success page when the request is successful.
      navigate(
        `/${organizationCmId}/trading/signing-right/cancelled-successfully`,
        {
          replace: true,
        },
      );
    } catch {
      // Do nothing, error is handled and logged by RTK Query.
    }
  };

  return (
    <WithGap>
      <WithGap gap="24" direction="row">
        <Button
          variant="outlined"
          iconLeft="close"
          data-trackid={getTrackId('cancel-signing', TrackingElement.Button)}
          data-test="cancel-signing-right-trading"
          onClick={handleCancelSigning}
          loading={cancelSigningRightState.isLoading}
          disabled={cancelSigningRightState.isLoading}
        >
          {texts.orders.signatureRight.cancelSigning}
        </Button>

        <Button
          iconRight="arrow-right"
          data-trackid={getTrackId(
            'continue-to-signing',
            TrackingElement.Button,
          )}
          data-test="restart-signing-right-trading"
          onClick={handleContinueToSigning}
        >
          {texts.orders.signatureRight.continueToSigning}
        </Button>
      </WithGap>

      {cancelSigningRightState.error! && (
        <Alert
          variant="error"
          title={texts.genericError}
          data-test="abort-error-signing-right-trading"
        />
      )}
    </WithGap>
  );
};
