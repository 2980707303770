import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { getAuth } from '@/auth/Auth';
import { appConfig } from '@/constants/config';
import { useKeyDown } from '@/hooks/useKeyDown';
import { useTexts } from '@/hooks/useTexts';
import { isOnboardingUserInProgress } from '@/util/isOnNewUserPage';

import { ChangeOrganizationDropdown } from './ChangeOrganizationDropdown';
import { LeftContainer } from './LeftContainer';
import { StorebrandLogoWithMediaQuery } from './Logo/StorebrandLogoWithMediaQuery';
import { Mask } from './Mask';
import { Menu } from './Menu';
import { SustainabilityWidget } from './Menu/widgets/SustainabilityWidget';
import { Nav } from './Nav';
import { NavbarButton } from './NavbarButton';
import { RightContainer } from './RightContainer';

interface Props {
  onlyLogout?: boolean;
}

export const Navbar: FC<Props> = ({ onlyLogout }) => {
  const texts = useTexts();

  const organizationsQuery = useOrganizationsQuery(undefined, {
    // New users does not have access to any organizations yet
    // and thus this query will return HTTP 403, an error code.
    // Skip this query for new users to avoid getting the error.
    skip: isOnboardingUserInProgress(),
  });

  const organizationCmId = useOrganizationCmId();

  const [displayMask, setDisplayMask] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);
  const [changeOrganizationIsOpen, setChangeOrganizationIsOpen] =
    useState<boolean>(false);

  const onEscapeKeyDown = useCallback((): void => {
    if (isSubMenuOpen) {
      setIsSubMenuOpen(false);
      // When the sub-menu is closed, focus within the navbar is lost. This
      // prevents the user from using 'escape' to shut the menu completely.
      // To address this, we'll auto-focus on the first menu item.
      menuRef.current
        ?.querySelector<
          HTMLAnchorElement | HTMLButtonElement
        >('li > a, li > button')
        ?.focus();
    } else {
      setMenuIsOpen(false);
      setChangeOrganizationIsOpen(false);
    }
  }, [isSubMenuOpen]);

  const navbarRef = useRef<HTMLDivElement | null>(null);
  useKeyDown('Escape', onEscapeKeyDown, navbarRef);

  const handleToggleMenu = (isOpen: boolean): void => {
    if (isOpen && changeOrganizationIsOpen) {
      setChangeOrganizationIsOpen(false);
    }
  };

  useEffect(() => {
    if (!menuIsOpen) {
      setIsSubMenuOpen(false);
    }
  }, [menuIsOpen]);

  const handleToggleChangeOrganization = (isOpen: boolean): void => {
    if (isOpen && menuIsOpen) {
      setMenuIsOpen(false);
    }
  };

  useEffect(() => {
    setDisplayMask(menuIsOpen || changeOrganizationIsOpen);
  }, [menuIsOpen, changeOrganizationIsOpen]);

  return (
    <>
      <AnimatePresence>{displayMask && <Mask />}</AnimatePresence>
      <Nav ref={navbarRef}>
        <Container>
          <LeftContainer>
            <StorebrandLogoWithMediaQuery to={`/${organizationCmId ?? ''}`} />

            {!onlyLogout && (
              <ButtonContainer>
                <Menu
                  widget={<SustainabilityWidget />}
                  onToggle={handleToggleMenu}
                  isOpen={menuIsOpen}
                  setIsOpen={setMenuIsOpen}
                  isSubMenuOpen={isSubMenuOpen}
                  setIsSubMenuOpen={setIsSubMenuOpen}
                  ref={menuRef}
                />

                {!!organizationCmId &&
                  organizationsQuery.data &&
                  organizationsQuery.data.length > 1 && (
                    <ChangeOrganizationDropdown
                      onToggle={handleToggleChangeOrganization}
                      isOpen={changeOrganizationIsOpen}
                      setIsOpen={setChangeOrganizationIsOpen}
                    />
                  )}
              </ButtonContainer>
            )}
          </LeftContainer>

          <RightContainer>
            <ButtonContainer>
              <NavbarButton
                onClick={() => getAuth().logout()}
                title={texts.topmenu.logout}
                dataTest="log-out-button"
                iconName="unlock"
              />
            </ButtonContainer>
          </RightContainer>
        </Container>
      </Nav>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: calc(${appConfig.maxPageWidth} - 48px);
  display: flex;
  gap: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 24px;
  list-style-type: none;
`;
