import last from 'lodash/last';
import { matchRoutes } from 'react-router';

import { UserInfo } from '@/auth/types/UserInfo';
import { hasAccessToRoute } from '@/routes/utils/hasAccessToRoute';

import { connectRoutes, NotFoundPageId } from '../connectRoutes';
/** Returns true if the user has access to the provided path. */
export const hasAccessToPath = (path: string, userInfo: UserInfo): boolean => {
  const matchedRoutes = matchRoutes(connectRoutes(), path);
  const filteredRoutes = (matchedRoutes || []).filter(
    (route) => !route.route.index,
  );
  const route = last(filteredRoutes)!;

  if (route.route.id === NotFoundPageId) {
    return false;
  }

  return hasAccessToRoute(route.route, userInfo);
};
