import { convertStringToEnum } from '@/util/convertStringToEnum';

import { DocumentFileDto } from '../dtos/DocumentFileDto';
import { DocumentCategory } from '../models/DocumentCategory';
import { DocumentFile } from '../models/DocumentFile';

export const mapDocumentFileDtoToModel = (
  dto: DocumentFileDto,
): DocumentFile => {
  return {
    id: dto.id,
    category: dto.category
      ? convertStringToEnum(dto.category, DocumentCategory)
      : undefined,
    fileType: dto.fileType,
    name: dto.name,
    owner: dto.owner,
    reportDate: dto.reportDate,
    size: dto.size,
    timeStamp: dto.timeStamp,
  };
};
