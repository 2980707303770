import { useCurrentUserInfo } from '@/auth/hooks';

import { useOrderPageContext } from '../OrderPageContext';

/** Returns true if there are any signatories in addition to the current user (cosigner). */
export const useHasCosigners = (): boolean => {
  const { currentOrder } = useOrderPageContext();
  const userInfo = useCurrentUserInfo();

  return (
    !!currentOrder &&
    currentOrder.signatories.some(
      (signatory) => signatory !== userInfo.userCmId,
    )
  );
};
