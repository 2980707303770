import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  formatDateArgument,
  useFetchAssetPerformanceQuery,
} from '@/api/performanceData/performanceDataApi';
import { DonutChart, DonutChartData } from '@/components/Charts';
import { UnitChips } from '@/components/Charts/components/UnitChips';
import { Helper } from '@/components/Helper';
import { H2, Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { AllocationTypeChips } from '@/pages/Presentation/filters/AllocationTypeChips';
import { AllocationType } from '@/pages/Presentation/types/AllocationType';
import { aggregateDonutChartData } from '@/pages/Presentation/utils/aggregateDonutChartData';
import { getAssetsByAllocationType } from '@/pages/Presentation/utils/getAssetsByAllocationType';
import { getDonutChartDataByUnit } from '@/pages/Presentation/utils/getDonutChartDataByUnit';
import { Unit } from '@/types/Unit';

interface Props {
  portfolioGroupShortName: string;
  date?: Date;
  trackId?: string;
}

const maxLengthDonutChartData = 12;
const today = new Date();

export const AssetAllocationDonutWidget: React.FC<Props> = ({
  portfolioGroupShortName,
  date,
  trackId,
}) => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  const [selectedUnit, setSelectedUnit] = useState<Unit>(Unit.Percent);
  const [selectedAllocationType, setSelectedAllocationType] =
    useState<AllocationType>(AllocationType.ProductGroup);

  const assetPerformanceQuery = useFetchAssetPerformanceQuery({
    portfolioGroup: portfolioGroupShortName,
    to: formatDateArgument(date ?? today),
  });

  const donutData = useMemo<DonutChartData>(() => {
    if ((assetPerformanceQuery.data?.assets?.length || 0) > 0) {
      const root = assetPerformanceQuery.data!.assets[0];
      const assets = getAssetsByAllocationType(root, selectedAllocationType);

      const donutChartData = getDonutChartDataByUnit(assets, selectedUnit);

      if (donutChartData.length > maxLengthDonutChartData) {
        return aggregateDonutChartData(
          donutChartData,
          maxLengthDonutChartData,
          texts.assetAllocation.chart.otherLegend,
        );
      }

      return donutChartData;
    }

    return [];
  }, [
    assetPerformanceQuery.data?.assets,
    selectedAllocationType,
    selectedUnit,
  ]);

  const updatedDate = assetPerformanceQuery.data?.date;

  return (
    <WithGap
      data-test={`widget-asset-allocation-donut-${portfolioGroupShortName}`}
    >
      <WithGap gap="8" direction="row">
        <H2>{texts.widgets.assetAllocation.donut.title}</H2>

        <Helper
          id="asset-allocation-donut"
          title={texts.widgets.assetAllocation.donut.help.title}
          description={texts.widgets.assetAllocation.donut.help.description}
        />
      </WithGap>

      <FilterContainer>
        <AllocationTypeChips
          checked={selectedAllocationType}
          onChange={setSelectedAllocationType}
        />
        <UnitChips
          id="asset-allocation-donut"
          checked={selectedUnit}
          onChange={setSelectedUnit}
        />
      </FilterContainer>

      {updatedDate && (
        <UpdatedText>
          {fmsWithTemplate(texts.charts.assetReturn.updated, {
            date: format(updatedDate, dateFormat),
          })}
        </UpdatedText>
      )}

      <DonutChart
        unit={selectedUnit}
        data={donutData}
        trackId={trackId}
        size={200}
      />
    </WithGap>
  );
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const UpdatedText = styled(Hint)`
  color: var(--color-disabled);
`;
