import InlineAlert from '@storeblocks/inline-alert/lib';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { defaultDateFormat } from '@/i18n/hooks/useUserDateFormat';
import { orderExpiryDate } from '@/util/orderExpiryDate';

import { TextShort, TextShortBold } from '../Typography';
import { WithGap } from '../WithGap';
import { GoToSigningButton } from './GoToSigningButton';
import { useOrderToSignData } from './hooks/useOrderToSignData';

export const OrderToSignAlert: React.FC = () => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();
  const { data, isSuccess } = useOrderToSignData();

  // Don't display anything while the request is not finished.
  // - The HTTP call should be lightening fast, so don't render anything while fetching.
  // - If there are errors, we don't know what failed, so don't display anything.
  if (!isSuccess || data?.pendingOrders.length === 0) {
    return null;
  }

  return (
    <WithGap data-test="pending-orders-alerts">
      {data!.pendingOrders.map((order) => (
        <Container
          key={order.orderId}
          data-test={`order-to-sign-${order.orderId}`}
        >
          <InlineAlert title={texts.pendingOrders.title} variant="info" />

          <Description>
            <div>
              {texts.pendingOrders.description.part1}{' '}
              <TextShortBold>{`${
                data!.portfolios.get(order.portfolioNumber)!.name
              } (${order.portfolioNumber})`}</TextShortBold>{' '}
              {texts.pendingOrders.description.part2}{' '}
              <div style={{ paddingTop: '16px' }}>
                {texts.pendingOrders.description.part3}{' '}
                <TextShort>{format(order.createdDate, dateFormat)}</TextShort>{' '}
                {texts.pendingOrders.description.part4}{' '}
                <TextShort>{order.createdBy}</TextShort>
              </div>
            </div>

            <div>
              <TextShortBold data-test="expiry-date">
                {fmsWithTemplate(texts.pendingOrders.description.part5, {
                  date: format(
                    orderExpiryDate(order.createdDate),
                    `${defaultDateFormat} HH:mm`,
                  ),
                })}
              </TextShortBold>
            </div>
          </Description>

          <GoToSigningButton pendingOrder={order} />
        </Container>
      ))}
    </WithGap>
  );
};

const Container = styled.div`
  border: 3px solid var(--color-info-accent);
  padding: 12px;
  padding-bottom: 26px;
`;

const Description = styled.div`
  padding-left: 64px;
`;
