import { Portfolio, usePortfoliosQuery } from '@/api/portfolios';

/**
 * Portfolios that can be used when creating a discretionary order.
 *
 * A discretionary order requires that the portfolio is a discretionary
 * portfolio and that it has one or more portfolio bank accounts
 * to deposit into or withdraw from.
 */
export const useDiscretionaryPortfolios = (): Portfolio[] => {
  const { data } = usePortfoliosQuery();

  return data!.portfolios
    .filter((portfolio) => portfolio.isDiscretionaryPortfolio)
    .filter((portfolio) => portfolio.portfolioBankAccounts.length > 0);
};
