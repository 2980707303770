import * as Sentry from '@sentry/react';

import { findCurrentPath } from '@/routes/utils/findCurrentPath';

import { getSessionId } from './sessionId';
import { LogEvent } from './types/LogEvent';

export const createLogEvent = ({
  type,
  description,
  data,
  level,
}: Pick<LogEvent, 'type' | 'description' | 'data' | 'level'>): LogEvent => {
  const logEvent: LogEvent = {
    type,
    level,

    description,
    data,

    url: findCurrentPath() ?? 'unknown',
    timestamp: Date.now(),
    sessionId: getSessionId(),
  };

  // The URL is a mandatory property and should always be set.
  if (logEvent.url === undefined) {
    // Log an error to make it easier to spot during development.
    console.error(
      'Could not find current path when creating log event. Something is wrong, it should have an URL.',
    );

    // Log an error to Sentry to make it easier to spot in production.
    const eventId = Sentry.captureException(
      new Error(
        `Could not find a valid route path when creating log event from url [${window.location.pathname}]`,
      ),
    );

    // Set the URL to unknown to avoid the backend throwing an error.
    // We still want to log the event even without a valid URL.
    logEvent.url = 'unknown';

    // Append the Sentry event ID to the log event so that we can easier find it in the log.
    logEvent.data = {
      eventId,
      ...logEvent.data,
    };
  }

  return logEvent;
};
