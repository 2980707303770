import Select from '@storeblocks/select';
import { useField } from 'formik';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { useCountriesAsSelectOption } from '../hooks/useCountriesAsSelectOption';
import { FormValues } from '../types/FormValues';

export const DualCitizenship: React.FC = () => {
  const texts = useTexts();

  const [field, meta] = useField<string>(nameof<FormValues>('dualCitizenship'));

  const countryOptions = useCountriesAsSelectOption();

  return (
    <Select
      {...field}
      id="dual-citizenship"
      label={texts.orders.signatureRight.pep.dualCitizenship.title}
      options={countryOptions}
      placeholder={texts.orders.signatureRight.pep.citizenship.placeholder}
      error={meta.touched && meta.error ? meta.error : undefined}
      fluid
    />
  );
};
