import { Parser } from '@json2csv/plainjs';
import { format } from 'date-fns';

import { Nav } from '@/api/funds/models/Nav';

export const createDownloadLink = (data: Nav[]): string | undefined => {
  if (!data.length) {
    return undefined;
  }

  const dataForCsv = data.map((fund) => {
    return {
      fundName: fund.fundName,
      isin: fund.isin,
      nav: fund.hasNav ? fund.nav : 0,

      navDate: fund.hasNav
        ? // Remove the timezone offset from the date
          // to avoid the date being off by one day.
          // I.e. we want to avoid 2021-05-02T00:00:00+01:00 -> 2021-05-01T00:00:00Z
          format(fund.navDate, 'yyyy-MM-dd')
        : '',
    };
  });

  const parser = new Parser();
  const csv = parser.parse(dataForCsv);

  const mimeType = 'text/csv';
  const blob = new Blob(['\uFEFF', csv], { type: mimeType });
  const dataURI = `data:${mimeType};charset=utf-8,${csv}`;

  const URL = window.URL || window.webkitURL;

  const downloadLink =
    typeof URL.createObjectURL === 'undefined'
      ? dataURI
      : URL.createObjectURL(blob);

  return downloadLink;
};
