import React from 'react';
import styled from 'styled-components';

import { FundHoldings } from '@/api/fundHoldings/models';
import { OrderPlacement } from '@/api/order/models/OrderPlacement';
import { OrderPlacementType } from '@/api/order/models/OrderPlacementType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';
import { getValueFromPercent } from '@/pages/Order/utils/getValueFromPercent';
import { formatNumber } from '@/util/formatNumber';

interface Props {
  orderPlacement: OrderPlacement;
  fundHoldings?: FundHoldings;
}

export const OrderPlacementValue: React.FC<Props> = ({
  orderPlacement,
  fundHoldings,
}) => {
  const { orders } = useTexts();
  const locale = useUserLocale();

  switch (orderPlacement.valueType) {
    case OrderValueType.Amount: {
      return (
        <Wrapper>
          {formatNumber(orderPlacement.value, locale, orderPlacement.currency)}
        </Wrapper>
      );
    }

    case OrderValueType.Percent: {
      if (orderPlacement.placementType === OrderPlacementType.Buy) {
        return <Wrapper>{`${orderPlacement.value}%`}</Wrapper>;
      }

      if (orderPlacement.value === 100) {
        return (
          <Wrapper>
            {`${orders.form.table.allHoldings} (${formatNumber(
              fundHoldings!.units,
              locale,
              orders.form.valueType.units.toLowerCase(),
            )})`}
          </Wrapper>
        );
      }

      const units = getValueFromPercent(
        orderPlacement.value,
        fundHoldings!.units,
      );

      return (
        <Wrapper>
          {`${orderPlacement.value}% (${formatNumber(
            units,
            locale,
            orders.form.valueType.units.toLowerCase(),
          )})`}
        </Wrapper>
      );
    }

    case OrderValueType.Units: {
      const isSellingAll = orderPlacement.value === fundHoldings!.units;

      if (isSellingAll) {
        return (
          <Wrapper>
            {`${orders.form.table.allHoldings} (${formatNumber(
              fundHoldings!.units,
              locale,
              orders.form.valueType.units.toLowerCase(),
            )})`}
          </Wrapper>
        );
      }

      return (
        <Wrapper>
          {`${formatNumber(
            orderPlacement.value,
            locale,
            orders.form.valueType.units.toLowerCase(),
          )}`}
        </Wrapper>
      );
    }
    default:
      throw new Error(`Invalid order value type [${orderPlacement.valueType}]`);
  }
};

const Wrapper = styled.div`
  text-align: right;
`;
