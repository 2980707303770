import { OrderType } from '@/api/order/models/OrderType';
import { FundOrder } from '@/pages/Order/types/Order';
import { getBasename } from '@/routes/utils/getBasename';

const orderPageUrl = (
  organizationCmId: string,
  orderType: FundOrder['orderType'],
): string => {
  const baseUrl = `${
    window.location.origin
  }${getBasename()}/${organizationCmId}/trading`;

  const urlMap = {
    [OrderType.Subscription]: `${baseUrl}/subscription`,
    [OrderType.Redemption]: `${baseUrl}/redemption`,
    [OrderType.Switch]: `${baseUrl}/switch`,
  };

  return urlMap[orderType];
};

const signingCompletedUrlSlug = 'signed';

export const createCompleteSigningUrl = (
  organizationCmId: string,
  orderType: FundOrder['orderType'],
): string => {
  const completedUrl = new URL(
    `${orderPageUrl(organizationCmId, orderType)}/${signingCompletedUrlSlug}`,
  );

  return completedUrl.toString();
};

export const createCancelSigningUrl = (
  organizationCmId: string,
  orderType: OrderType,
  orderId?: number,
): string => {
  const baseUrl = `${
    window.location.origin
  }${getBasename()}/${organizationCmId}/trading`;

  const cancelUrl = new URL(`${baseUrl}/signing-cancelled`);

  if (orderId) {
    cancelUrl.searchParams.append('orderId', orderId.toString());
  }

  return cancelUrl.toString();
};
