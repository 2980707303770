import { format } from 'date-fns';
import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useMultipleAssetPerformanceQuery } from '@/api/performanceData/multiPortfolioReturnsApi';
import { formatDateArgument } from '@/api/performanceData/performanceDataApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { isNotReady } from '@/api/utils/isNotReady';
import {
  AssetReturnChart,
  AssetReturnPeriod,
  AssetReturnPeriodChips,
} from '@/components/AssetReturnChart';
import { H1, H2, Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useUserDateFormat } from '@/i18n/hooks';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { AvoidPageBreaksInside } from '../components/AvoidPageBreakInside';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { CollapsibleFilterContainer } from '../filters/CollapsibleFilterContainer';
import { DateFilter } from '../filters/DateFilter';
import { Filter } from '../filters/Filter';
import { useDateFilterText } from '../filters/hooks/useDateFilterText';
import { getAssetReturnPeriodText } from '../filters/utils/getAssetReturnPeriodText';
import {
  useFilterForScreen,
  usePresentationContext,
} from '../PresentationContext';

const screenKey = 'asset-return';

interface Filters {
  selectedPeriod: AssetReturnPeriod;
}

export const AssetReturnScreen: React.FC = () => {
  const texts = useTexts();

  const dateFormat = useUserDateFormat();

  const { setFilter, globalFilter } = usePresentationContext();

  const filters = useFilterForScreen<Filters>(screenKey, {
    selectedPeriod: AssetReturnPeriod.YTD,
  });

  const multiAssetPerformanceQuery = useMultipleAssetPerformanceQuery({
    portfolioGroups: globalFilter.portfolioGroups.map(
      (portfolioGroup) => portfolioGroup.id,
    ),
    to: formatDateArgument(globalFilter.date),
  });

  const portfoliosQuery = usePortfoliosQuery();

  const dateFilterText = useDateFilterText();

  const selectedFilterTexts = [
    getAssetReturnPeriodText(filters.selectedPeriod, texts),
    dateFilterText,
  ];

  const getPortfolioHeader = (portfolioId: string): string => {
    const portfolio =
      portfoliosQuery.data!.portfolioGroupById.get(portfolioId)!;

    return `${portfolio.id} - ${portfolio.name}`;
  };

  return (
    <A4WidthForPrint>
      <WithGap data-test={`${screenKey}-screen`}>
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.assetReturn.title}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>
        <CollapsibleFilterContainer selectedFilterTexts={selectedFilterTexts}>
          <WithGap>
            <WithGap direction="row">
              <Filter
                label={texts.pages.presentation.filters.assetReturnPeriod.label}
              >
                <AssetReturnPeriodChips
                  id={`${screenKey}-period-filter`}
                  checked={filters.selectedPeriod}
                  onChange={(selectedPeriod) =>
                    setFilter<Filters>(screenKey, { selectedPeriod })
                  }
                />
              </Filter>
              <DateFilter />
            </WithGap>
          </WithGap>
        </CollapsibleFilterContainer>

        {isNotReady(multiAssetPerformanceQuery) ? (
          <QueryState
            query={multiAssetPerformanceQuery}
            customTexts={{
              noData: {
                title: texts.pages.presentation.filters.noData.title,
                description: texts.pages.presentation.filters.noData.message,
              },
            }}
          />
        ) : (
          <WithGap gap="48">
            {multiAssetPerformanceQuery.data!.assetPerformances.map(
              (assetPerformance) => (
                <AvoidPageBreaksInside key={assetPerformance.portfolioGroup}>
                  <WithGap gap="16">
                    <H2>
                      {getPortfolioHeader(assetPerformance.portfolioGroup)}
                    </H2>

                    <Hint>
                      {`${
                        texts.pages.presentation.screens.assetReturn.lastUpdated
                      } ${format(assetPerformance.date, dateFormat)}`}
                    </Hint>

                    <AssetReturnChart
                      key={assetPerformance.portfolioGroup}
                      assets={assetPerformance.assets}
                      period={filters.selectedPeriod}
                      unit={globalFilter.unit}
                    />
                  </WithGap>
                </AvoidPageBreaksInside>
              ),
            )}
          </WithGap>
        )}
      </WithGap>
    </A4WidthForPrint>
  );
};
