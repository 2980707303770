import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { DocumentCategoriesReply } from './dtos/DocumentCategoriesReply';
import { DocumentFileDto } from './dtos/DocumentFileDto';
import { DocumentFilesReply } from './dtos/DocumentFilesReply';
import { DocumentCategory } from './models/DocumentCategory';
import { DocumentFile } from './models/DocumentFile';
import { mapDocumentFileDtoToModel } from './utils/mapDocumentFileDtoToModel';
import { transformDocumentCategoriesReply } from './utils/transformDocumentCategoriesReply';
import { transformDocumentFilesReply } from './utils/transformDocumentFilesReply';

export interface FetchDocumentFilesParams {
  name?: string;
  startDate?: string;
  endDate?: string;
  category?: DocumentCategory;
}

interface UploadDocumentFileParams {
  file: File;
  onProgress?: (event: AxiosProgressEvent) => void;
  onReceivedCancelToken?: (cancelToken: CancelTokenSource) => void;
}

interface UpdateDocumentFileParams {
  fileId: string;
  fileName: string;
  category?: DocumentCategory;
}

export const documentArchiveApi = createApi({
  reducerPath: 'documentArchiveApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['DocumentFiles'],
  endpoints: (builder) => ({
    documentCategories: builder.query<Array<DocumentCategory>, void>({
      query: () => ({
        url: `${window.config.connectBaseApiUrl}/api/documents/categories`,
      }),
      transformResponse: (dto: DocumentCategoriesReply) => {
        return transformDocumentCategoriesReply(dto);
      },
    }),

    documentFiles: builder.query<Array<DocumentFile>, FetchDocumentFilesParams>(
      {
        query: ({ name, startDate, endDate, category }) => ({
          url: `${window.config.connectBaseApiUrl}/api/documents`,
          params: {
            name: name,
            startDate: startDate,
            endDate: endDate,
            category: category,
          },
        }),
        providesTags: ['DocumentFiles'],
        transformResponse: (dto: DocumentFilesReply) => {
          return transformDocumentFilesReply(dto);
        },
      },
    ),

    uploadDocumentFile: builder.mutation<
      DocumentFile,
      UploadDocumentFileParams
    >({
      query: ({ file, onProgress, onReceivedCancelToken }) => {
        /** Get a new cancel token for this upload */
        const cancelToken = axios.CancelToken.source();
        if (onReceivedCancelToken) {
          onReceivedCancelToken(cancelToken);
        }

        /** Build form data for file upload */
        const formData = new FormData();
        formData.append('file', file, file.name);

        return {
          url: `${window.config.connectBaseApiUrl}/api/documents`,
          method: 'POST',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 600000,
          onUploadProgress: onProgress || null,
          cancelToken: cancelToken?.token,
        };
      },
      transformResponse: (dto: Array<DocumentFileDto>) => {
        return mapDocumentFileDtoToModel(dto[0]);
      },
    }),

    updateDocumentFile: builder.mutation<
      DocumentFile,
      UpdateDocumentFileParams
    >({
      query: ({ fileId, fileName, category }) => ({
        url: `${window.config.connectBaseApiUrl}/api/documents`,
        method: 'PATCH',
        data: {
          id: fileId,
          name: fileName,
          category: category,
        },
      }),
      transformResponse: (dto: DocumentFileDto) => {
        return mapDocumentFileDtoToModel(dto);
      },
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await queryFulfilled;

        // Invalidate the cache after a short delay to ensure the file has
        // been properly updated on the document archive server.
        // We have been experiencing issues where it takes a short time
        // before the file is properly updated on the server.
        setTimeout(() => {
          dispatch(documentArchiveApi.util.invalidateTags(['DocumentFiles']));
        }, 1000);
      },
    }),

    deleteDocumentFile: builder.mutation<void, string>({
      query: (fileId) => ({
        url: `${window.config.connectBaseApiUrl}/api/documents/${fileId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        await queryFulfilled;

        // Invalidate the cache after a short delay to ensure the file has
        // been properly deleted on the document archive server.
        // We have been experiencing issues where it takes a short time
        // before the file is properly deleted on the server.
        setTimeout(() => {
          dispatch(documentArchiveApi.util.invalidateTags(['DocumentFiles']));
        }, 1000);
      },
    }),
  }),
});

export const {
  useDocumentCategoriesQuery,
  useDocumentFilesQuery,
  useUploadDocumentFileMutation,
  useUpdateDocumentFileMutation,
  useDeleteDocumentFileMutation,
  util,
} = documentArchiveApi;
