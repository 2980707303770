import { FC } from 'react';
import React from 'react';

import { Money } from '@/api/portfolioGroupHoldings/models/Money';
import { useUserLocale } from '@/i18n/hooks';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

interface Props {
  money: Money | null;
}

export const MoneyCell: FC<Props> = ({ money }) => {
  const locale = useUserLocale();

  if (!money) {
    return null;
  }

  return (
    <span>
      {formatNumber(roundHalfEven(money.amount, 0), locale, money.currency)}
    </span>
  );
};
