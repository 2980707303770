import Checkbox from '@storeblocks/checkbox';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { styled } from 'styled-components';

import { DailyTradeReport } from '@/api/reports/models/DailyTradeReports';
import { FileNameColumn } from '@/components/Table/FileNameColumn';
import { JSXTableCell, TableRow } from '@/types/tables';

export type ContractNotesRow = TableRow<{
  name: JSXTableCell;
  date: JSXTableCell;
  selectFile: JSXTableCell;
}>;

export const getContractNotesRowsWithCheckbox = (
  contractNotes: DailyTradeReport[],
  checkedFileIds: string[],
  onDownload: (fileId: string) => Promise<void>,
  onCheckedClicked: (fileId: string, checked: boolean) => void,
  dateFormat: string,
): ContractNotesRow[] =>
  contractNotes.map((contractNote) => {
    // Find whether or not the file is checked.
    const checked = checkedFileIds.some((fileId) => fileId === contractNote.id);

    return {
      date: {
        displayContent: (
          <DateCell>
            {format(parseISO(contractNote.reportDateISO), dateFormat)}
          </DateCell>
        ),
        sortContent: contractNote.reportDateISO,
      },
      name: {
        displayContent: (
          <FileNameColumn
            filename={contractNote.displayName}
            fileId={contractNote.id}
            fileExtension={contractNote.fileType}
            onDownload={onDownload}
          />
        ),
        sortContent: contractNote.displayName,
      },
      selectFile: {
        displayContent: (
          <Align>
            <Checkbox
              id={`file-download-checkbox-${contractNote.id}`}
              name={`file-download-checkbox-${contractNote.id}`}
              value={checked}
              options={[
                {
                  id: `file-${contractNote.id}`,
                  value: contractNote.id,
                  label: '',
                },
              ]}
              onChange={() => onCheckedClicked(contractNote.id, !checked)}
            />
          </Align>
        ),
        sortContent: '',
      },
    };
  });

const DateCell = styled.div`
  padding: 16px;
  height: 100%;
  line-height: 24px;
`;

const Align = styled.div`
  display: flex;
  justify-content: center;

  /* Make the center of the checkbox line up with the center of the report date and filename. */
  padding-top: 4px;
`;
