import { format } from 'date-fns';
import React, { FC } from 'react';
import { styled } from 'styled-components';

import { Transaction } from '@/api/transactions/domain/Transaction';
import { useTexts } from '@/hooks/useTexts';

interface Props {
  transaction: Transaction;
}

export const TransactionDetailsRow: FC<Props> = ({ transaction }) => {
  const texts = useTexts();

  return (
    <tr>
      <TableData colSpan={7}>
        <TablesContainer>
          <StyledTable>
            <tr>
              <th>{texts.transactions.table.details.portfolio}</th>
              <StyledTd data-test="transaction-details-portfolioGroupId">
                {transaction.portfolioGroupId}
              </StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.portfolioName}</th>
              <StyledTd>{transaction.portfolioGroupName}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.isin}</th>
              <StyledTd>{transaction.isin}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.securityName}</th>
              <StyledTd>{transaction.securityName}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.orderNumber}</th>
              <StyledTd>{transaction.orderNumber}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.orderType}</th>
              <StyledTd>{transaction.transactionType}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.paymentCode}</th>
              <StyledTd>{transaction.paymentCode}</StyledTd>
            </tr>
            {transaction.paymentDate && (
              <tr>
                <th>{texts.transactions.table.details.tradeDate}</th>
                <StyledTd>
                  {format(transaction.paymentDate, 'dd.MM.yyyy')}
                </StyledTd>
              </tr>
            )}
            {transaction.tradeDate && (
              <tr>
                <th>{texts.transactions.table.details.settlementDate}</th>
                <StyledTd>
                  {format(transaction.tradeDate, 'dd.MM.yyyy')}
                </StyledTd>
              </tr>
            )}
            <tr>
              <th>{texts.transactions.table.details.currency}</th>
              <StyledTd>{transaction.currency}</StyledTd>
            </tr>
          </StyledTable>
          <StyledTable>
            <tr>
              <th>{texts.transactions.table.details.status}</th>
              <StyledTd>{transaction.status}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.counterParty}</th>
              <StyledTd>{transaction.counterPartyId}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.amount}</th>
              <StyledTd alignRight>{transaction.nominal}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.price}</th>
              <StyledTd alignRight>{transaction.price}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.exchangeRate}</th>
              <StyledTd alignRight>{transaction.exchangeRate}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.accruedInterest}</th>
              <StyledTd alignRight>{transaction.accruedInterest}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.currentValue}</th>
              <StyledTd alignRight>{transaction.currentValue}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.bookValue}</th>
              <StyledTd alignRight>{transaction.bookValue}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.profitLossSecurity}</th>
              <StyledTd alignRight>{transaction.profitLossSecurity}</StyledTd>
            </tr>
            <tr>
              <th>{texts.transactions.table.details.profitLossCurrency}</th>
              <StyledTd alignRight>{transaction.profitLossCurrency}</StyledTd>
            </tr>
          </StyledTable>
        </TablesContainer>
      </TableData>
    </tr>
  );
};

const TableData = styled.td`
  padding: 16px 64px;
`;

const TablesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledTable = styled.table`
  flex: 1;
  table-layout: fixed;

  // By setting a max-height of 1px on the table we ensure that table takes only as much
  // height as its content. This way we can have different amount of rows in each table
  // but the table height for both tables will remain the same.
  max-height: 1px;

  th {
    text-align: left;
    padding-top: 24px;
    padding-right: 48px;

    // This code ensures that the width of the column is the same as its content making it dynamic.
    // We have to override default width to make the cell collapse,
    // then by adding white-space: nowrap we ensure the cells are no wider then its content.
    width: 1px;
    white-space: nowrap;
  }
`;

const StyledTd = styled.td<{ alignRight?: boolean }>`
  padding-top: 24px;
  text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;
