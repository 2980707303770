import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Returns a ref that triggers the callback function when clicked outside.
 */
export const useClickOutsideRef = <T extends Element>(
  callback: () => void,
): MutableRefObject<T> => {
  const ref = useRef<T>(null!);

  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
        event.stopPropagation();
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref]);

  return ref;
};
