import { Options } from 'highcharts';

import { useUserLocale } from '@/i18n/hooks';
import { Unit } from '@/types/Unit';

import { getUnitNumberFormat } from '../utils/getUnitNumberFormat';
import { ColumnChartData } from './types/ColumnChartData';

export const useColumnChartOptions = (
  data: Array<ColumnChartData>,
  min: number | undefined,
  max: number | undefined,
  unit: Unit,
  showLegend?: boolean,
): Options => {
  const locale = useUserLocale();

  return {
    chart: {
      type: 'column',
      height: 500,
      numberFormatter(value) {
        return getUnitNumberFormat(value, unit, locale);
      },
    },
    plotOptions: {
      column: {
        maxPointWidth: 36,
        tooltip: {
          headerFormat: undefined,
        },
        borderWidth: 1,
        borderColor: 'var(--color-on-background)',
      },
    },
    yAxis: {
      min,
      max,
      title: undefined,
      plotLines: [
        {
          color: 'var(--color-on-background)',
          width: 1,
          value: 0,
          zIndex: 1,
          dashStyle: 'Dash',
        },
      ],
    },
    xAxis: {
      type: 'category',
      labels: {
        rotation: -45,
        style: {
          fontSize: '14px',
          fontFamily: 'Open Sans',
          fontWeight: '400',
          lineHeight: '24px',
        },
      },
    },
    series: data.map((series) => ({
      type: 'column',
      name: series.title,
      data: series.dataPoints,
    })),
    colors: [
      '#D2F5F9',
      'var(--color-blue-70)',
      '#7ACDDC',
      'var(--color-blue-50)',
      'var(--color-green-10)',
      'var(--color-green-50)',
      'var(--color-green-70)',
      'var(--color-green-30)',
      'var(--color-beige-70)',
      'var(--color-beige-30)',
      'var(--color-beige-90)',
      'var(--color-beige-60)',
    ],
    title: undefined,
    legend: {
      enabled: showLegend,
      itemStyle: {
        fontSize: '16px',
        fontWeight: '400',
      },
      itemDistance: 24,
      itemMarginBottom: 8,
    },
    credits: {
      enabled: false,
    },
  };
};
