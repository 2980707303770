/**
 * The Keycloak token parsed data with only the Storebrand specific behalf_of field.
 */
interface TokenParsedWithBehalfOf {
  on_behalf_of?: {
    resource_owner: string;
  };

  // All other fields from the token are set to unknown.
  [key: string]: unknown;
}

export function getOrganizationCmIdFromToken(
  tokenParsed: TokenParsedWithBehalfOf,
): string | null {
  const resourceOwner = tokenParsed.on_behalf_of?.resource_owner;

  // The behalf_of CMID is only present in the token when the user
  // is logged in to a specific organization.
  if (!resourceOwner) {
    // -> The user is not logged in to a specific organization.
    return null;
  }

  // The resource owner is a string with the format: "cm_id:123456789" or "urn:cm_id:123456789" (legacy).
  const behalfOfCmId = resourceOwner.split('cm_id:').pop()!;

  const isValidCmId = new RegExp('^[0-9]+$').test(behalfOfCmId);

  // Since this function also returns null when the CMID is not present,
  // make sure we have a valid CMID in case something changes in Keycloak.
  if (!isValidCmId) {
    throw new Error(
      `Failed to parse behalf_of CMID from token. Parsed value: [${behalfOfCmId}].`,
    );
  }

  return behalfOfCmId;
}
