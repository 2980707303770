import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { useAddUserMutation } from '@/api/users/usersApi';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';

import { OrganizationFormSelector } from '../OrganizationFormSelector';
import { RolesSelection } from '../RolesSelection';
import { AddUserModalProps } from './AddUserModalProps';
import { UserAdminForm } from './UserAdminForm';
import {
  getValidationSchemaAddAllUsers,
  OrganizationUserFormValues,
} from './UserFormSchema';

const emptyInitialValues: OrganizationUserFormValues = {
  givenName: '',
  familyName: '',
  email: '',
  roles: [],
  organizations: [],
};

type Props = AddUserModalProps;

export const AddAllUsersModal: FC<Props> = ({ show, onClose }) => {
  const [submitFailed, setSubmitFailed] = useState(false);

  const texts = useTexts();

  const [addUser, addUserMutation] = useAddUserMutation();

  const handleSubmit = async (
    formUser: OrganizationUserFormValues,
  ): Promise<void> => {
    await addUser({
      firstName: formUser.givenName,
      surname: formUser.familyName,
      email: formUser.email!,
      roles: formUser.roles,
      organizations: formUser.organizations!.map((org) => org.organizationId),
    });
  };

  const formik = useFormik({
    initialValues: emptyInitialValues,
    onSubmit: handleSubmit,
    validationSchema: getValidationSchemaAddAllUsers(texts),
    enableReinitialize: true,
  });

  const handleOnClose = (): void => {
    // Reset state as this component is a singleton triggered by the `show` boolean.
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (addUserMutation.isSuccess) {
      handleOnClose();
    }
  }, [addUserMutation.isSuccess]);

  useEffect(() => {
    if (addUserMutation.isError) {
      setSubmitFailed(true);
    }
  }, [addUserMutation.isError]);

  return (
    <FormikProvider value={formik}>
      <UserAdminForm
        show={show}
        onClose={handleOnClose}
        header={texts.adminModal.addUserButton}
        isSubmitting={formik.isSubmitting}
        submitFailed={submitFailed}
        currentMode="addAll"
        touched={formik.touched}
        errors={formik.errors}
        dirty={formik.dirty}
        trackingModal="add-all-user"
      >
        <OrganizationFormSelector />

        <Show when={(formik.values.organizations?.length ?? 0) > 0}>
          <RolesSelection
            error={
              formik.submitCount > 0
                ? (formik.errors.roles as string)
                : undefined
            }
            displayDisclaimer
          />
        </Show>
      </UserAdminForm>
    </FormikProvider>
  );
};
