import React from 'react';
import { styled } from 'styled-components';

import { usePortfoliosQuery } from '@/api/portfolios';
import { useTexts } from '@/hooks/useTexts';

import { createPortfolioBankAccountLabel } from '../utils/createPortfolioBankAccountLabel';

interface Props {
  portfolioBankAccountNumber: string;
}

/**
 * Display information about the provided portfolio bank account.
 */
export const SinglePortfolioBankAccount: React.FC<Props> = ({
  portfolioBankAccountNumber,
}) => {
  const texts = useTexts();

  const [portfolioBankAccount] = usePortfoliosQuery()
    .data!.portfolios.flatMap((portfolio) => portfolio.portfolioBankAccounts)
    .filter((account) => account.accountNumber === portfolioBankAccountNumber);

  return (
    <div data-test="portfolio-bank-account">
      <Title>{texts.orders.form.portfolioBankAccount}</Title>

      <Details>{createPortfolioBankAccountLabel(portfolioBankAccount)}</Details>
    </div>
  );
};

const Title = styled.div`
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  margin-bottom: 8px;
`;

const Details = styled.div`
  line-height: 24px;
  word-wrap: break-word;
`;
