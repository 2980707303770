import Input from '@storeblocks/input';
import { useField } from 'formik';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { FormValues } from '../types/FormValues';

export const PepReason: React.FC = () => {
  const texts = useTexts();

  const [field, meta] = useField<string>(nameof<FormValues>('pepReason'));

  return (
    <Input
      {...field}
      id="pep-reason"
      label={texts.orders.signatureRight.pep.reason.title}
      hint={texts.orders.signatureRight.pep.reason.description}
      error={meta.touched && meta.error ? meta.error : undefined}
      fluid
    />
  );
};
