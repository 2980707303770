import { SystemIcon } from '@storeblocks/icon';
import { AnimatePresence, motion } from 'framer-motion';
import React, { AriaAttributes, FC } from 'react';
import styled from 'styled-components';

import { TextShortBold } from '../Typography';

interface Props extends AriaAttributes {
  title: string;
  dataTest?: string;
  onClick: () => void;
  active?: boolean;
  iconName?: string;
}

export const NavbarButton: FC<Props> = ({
  title,
  dataTest,
  onClick,
  active,
  iconName,
  ...rest
}) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    onClick();
    event.stopPropagation();
  };

  return (
    <Wrapper
      onClick={handleOnClick}
      className={active ? 'active' : ''}
      data-test={dataTest}
      {...rest}
    >
      <Content>
        {iconName && <SystemIcon name={iconName} size={24} />}
        <TextShortBold>{title}</TextShortBold>
      </Content>
      <AnimatePresence>
        {active && (
          <Chevron
            key="chevron"
            transition={{ ease: 'easeInOut' }}
            initial={{ borderTopWidth: '0px' }}
            animate={{ borderTopWidth: '16px' }}
            exit={{ borderTopWidth: '0px' }}
          />
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled.button`
  color: var(--color-on-background);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;

  padding: 0 16px;

  &:hover {
    cursor: pointer;
    background-color: var(--color-overlay-hover-darken);
  }

  &:is(:hover, .active) > div > span {
    color: var(--color-primary);
  }

  &.active {
    background-color: var(--color-surface);
  }
`;

const Content = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Chevron = styled(motion.div)`
  position: absolute;
  top: 72px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top-color: var(--color-surface);
  border-top-style: solid;
  z-index: 3;
`;
