import Input from '@storeblocks/input';
import Select from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { usePaymentOptionsQuery } from '@/api/paymentOptions/paymentOptionsApi';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { WithdrawalOrderFormValues } from '../../types/WithdrawalOrderFormValues';

const otherBankAccountOption = 'other';

export const WithdrawalExternalBankAccountSelect: React.FC = () => {
  const texts = useTexts();
  const { data } = usePaymentOptionsQuery();

  const { values, setFieldValue } =
    useFormikContext<WithdrawalOrderFormValues>();

  const [field, meta, helper] = useField<string>(
    nameof<WithdrawalOrderFormValues>('externalBankAccount'),
  );

  const options = data!.paymentOptions
    .filter(
      (paymentOption) => paymentOption.denominationCurrency === values.currency,
    )
    .map((paymentOption) => ({
      value: paymentOption.accountNumber,
      label: paymentOption.accountNumber,
    }));

  // Add the option to manually enter a bank account number
  options.push({
    value: otherBankAccountOption,
    label: texts.orders.form.payment.manualBankAccount,
  });

  const getInitialPaymentOption = (): string => {
    const isExistingBankAccount =
      field.value &&
      data!.paymentOptions.some(
        (paymentOption) => paymentOption.accountNumber === field.value,
      );

    // If the user has selected a bank account that already exists we return that value.
    if (isExistingBankAccount) {
      return field.value;
    }

    // If the bank account number is defined but not in the list of payment options we return other.
    if (field.value) {
      return otherBankAccountOption;
    }

    // If there are no payment options we return other.
    if (data!.paymentOptions.length === 0) {
      return otherBankAccountOption;
    }

    // We default to an empty string if the bank account number is not defined.
    return '';
  };

  const [selectedPaymentOption, setSelectedPaymentOption] = useState<string>(
    getInitialPaymentOption,
  );

  const handleChanged = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedPaymentOption(event.target.value);

    // Clear the bank account number if the user selects other bank account.
    if (event.target.value === otherBankAccountOption) {
      setFieldValue('externalBankAccount', '');
      helper.setTouched(false, false);
    } else {
      setFieldValue('externalBankAccount', event.target.value);
    }
  };

  return (
    <WithGap gap="32">
      <Select
        id="external-bank-account-selector"
        name="external-bank-account-selector"
        label={texts.orders.form.withdrawal.externalBankAccount.title}
        hint={texts.orders.form.withdrawal.externalBankAccount.description}
        placeholder={
          texts.orders.form.withdrawal.externalBankAccount.placeholder
        }
        options={options}
        error={
          selectedPaymentOption !== otherBankAccountOption &&
          meta.touched &&
          meta.error
        }
        value={selectedPaymentOption}
        onChange={handleChanged}
        fluid
      />

      {selectedPaymentOption === otherBankAccountOption && (
        <Input
          id={'external-bank-account-input'}
          label={texts.orders.form.payment.bankAccountNumber}
          error={meta.touched && meta.error}
          fluid
          {...field}
        ></Input>
      )}
    </WithGap>
  );
};
