import * as Yup from 'yup';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { UserType } from '@/config/types';
import { Locale } from '@/i18n/locale';
import { nameof } from '@/util/nameof';

import { SubscriptionOrderFormValues } from '../types/SubscriptionOrderFormValues';
import { baseSubscriptionFundStateValidationSchema } from './rules/baseSubscriptionFundStateValidationSchema';
import { cosignerFieldValidationSchema } from './rules/cosignerFieldValidationSchema';
import { portfolioNameFieldValidationSchema } from './rules/portfolioNameFieldValidationSchema';
import { signatoryOptionValidationSchema } from './rules/signatoryOptionValidationSchema';
import { signerFieldValidationSchema } from './rules/signerFieldValidationSchema';
import { signingProviderFieldValidationSchema } from './rules/signingProviderFieldValidationSchema';
import { subscriptionInAmountFundStateValidationSchema } from './rules/subscriptionInAmountFundStateValidationSchema';
import { subscriptionPaymentValidationSchema } from './rules/subscriptionPaymentValidationSchema';

interface ValidationSchema {
  errorMessages: ErrorMessages;
  locale: Locale;
  userType: UserType;
}

interface ErrorMessages {
  portfolioRequired: string;
  mustHaveAtLeastOneFund: string;
  signatoryOptionRequired: string;
  signerRequired: string;
  cosignerRequired: string;
  signingProviderRequired: string;
  paymentPlacementRequired: string;
  minimumSubscriptionAmount: string;
  portfolioBankAccountNumberRequired: string;
}

export const createSubscriptionValidationSchema = ({
  errorMessages,
  locale,
  userType,
}: ValidationSchema): Yup.SchemaOf<SubscriptionOrderFormValues> => {
  return Yup.object({
    toPortfolioShortName: portfolioNameFieldValidationSchema({
      portfolioRequired: errorMessages.portfolioRequired,
    }),

    toValueType: Yup.mixed<OrderValueType>()
      .oneOf(Object.values(OrderValueType))
      .required(),

    toFunds: Yup.array()
      .of(baseSubscriptionFundStateValidationSchema)
      .min(1, errorMessages.mustHaveAtLeastOneFund)
      .test({
        // Ensure that the user has input an amount to buy.
        message: errorMessages.mustHaveAtLeastOneFund,
        test: (funds) => (funds || []).some((fund) => fund.value),
      })
      // Validate from funds when value type is Amount.
      .when(nameof<SubscriptionOrderFormValues>('toValueType'), {
        is: OrderValueType.Amount,
        then: Yup.array().of(
          subscriptionInAmountFundStateValidationSchema(locale, {
            minimumSubscriptionAmountMessage:
              errorMessages.minimumSubscriptionAmount,
          }),
        ),
      })
      .required(),

    payments: subscriptionPaymentValidationSchema({
      paymentPlacementRequired: errorMessages.paymentPlacementRequired,
      portfolioBankAccountNumberRequired:
        errorMessages.portfolioBankAccountNumberRequired,
    }),

    signatoryOption: signatoryOptionValidationSchema({
      signatoryOptionRequired: errorMessages.signatoryOptionRequired,
    }),

    signer: signerFieldValidationSchema({
      signerRequired: errorMessages.signerRequired,
    }),

    cosigner: cosignerFieldValidationSchema({
      cosignerRequired: errorMessages.cosignerRequired,
    }),

    signingProvider: signingProviderFieldValidationSchema(userType, {
      signingProviderRequired: errorMessages.signingProviderRequired,
    }),
  });
};
